import { Component, OnInit } from '@angular/core';
import { DataService } from '@app/shared/services/data.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'appc-gifts',
  templateUrl: './gifts.component.html',
  styleUrls: ['./gifts.component.scss']
})
export class GiftsComponent implements OnInit {

  giftsData: any[];
  apiBaseUri: any;

  constructor(private dataService: DataService) {
    this.apiBaseUri = environment.apiUrl;
  }

  ngOnInit() {
    this.getGiftsData();
  }

  getGiftsData() {
    let uri = `offerassignments/offer-filter-type/1/consumer/${localStorage.getItem("consumerId")}`
    this.dataService.get(uri)
      .subscribe((response: any) => {
        this.giftsData = response.data;
      });
  }

}
