import { BaseComponent } from '@app/components/base/base.component';
import { Component, OnInit, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'appc-popular-gift-item',
  templateUrl: './popular-gift-item.component.html',
  styleUrls: ['./popular-gift-item.component.scss']
})
export class PopularGiftItemComponent extends BaseComponent implements OnInit {

  @Input() item: any;
  apiBaseUri: any;
  color: string;
  backgroundColor:string;
  borderBottom:string;

  constructor(private router: Router) {
    super();
    this.apiBaseUri = environment.apiUrl;
  }

  ngOnInit() {
  }

  setDesign(){
    this.color = 'color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.backgroundColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.borderBottom = 'border-bottom:3px solid ' + this.getCssWithImportant(this.themeInfo?.base);
  }

  onSendPopularGift(item: any) {

    let giftInfo = {
      "id": item.merchantId,
      "name": item.merchant,
      "companyLogoUrl": item.merchantLogoUrl,
      "giftAmount": item.offerPrice,
      "isPopularGift": true
    };

    let navigationExtras: NavigationExtras = {
      state: {
        transd: 'giftInformation',
        workQueue: false,
        services: 1,
        code: giftInfo
      }
    };

    this.router.navigate(['send-gift-information'], navigationExtras);
  }

}
