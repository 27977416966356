import { Injectable } from '@angular/core';

declare var alertify:any;
@Injectable({
    providedIn: 'root'
  })
export class AlertifyService {
    constructor() {
        alertify.set('notifier','position', 'top-center');
       
    }

    confirm(message: string, okCallback: () => any) {
        alertify.confirm(message, function(e) {
        if (e) {
            okCallback();
        } else {
        }
        }).setHeader('<em> </em> '); ;
    }
    success(message: string) {
        alertify.success(message);
    }
    error(message: string) {
        alertify.error(message);
    }

    alert(title: string, message: string){
        alertify.alert(title, message, function(){ }).set('closable', false);
    }

    
}