import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { DataService } from '@app/shared';

@Component({
  selector: 'appc-gift-confirmation',
  templateUrl: './gift-confirmation.component.html',
  styleUrls: ['./gift-confirmation.component.scss']
})
export class GiftConfirmationComponent extends BaseComponent {

  giftInformation: any;

  //for design
  skipBtnColor: string;
  continueBtnColor: string;
  circleColor: string;
  constructor(private router: Router, private dataService: DataService) {
    super();
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state != undefined && state.code != undefined) {
      this.giftInformation = state.code;
      console.log("gift information");
      console.log(this.giftInformation);
    }
  }

  ngOnInit() {
    if (this.giftInformation?.hasDiscount) {
      this.getDiscounts(this.giftInformation?.merchantId, this.giftInformation?.giftAmount);
    }
  }

  getDiscounts(merchantId: any, giftAmount: any) {
    let uri = `GiftCardOrders/check-discount`;
    let payload = {
      "merchantId": merchantId,
      "orderTotalAmount": giftAmount
    }

    this.dataService.post(uri, payload).subscribe((res: any) => {
      this.giftInformation.discountAmount = res.discountAmount;
      this.giftInformation.payableAmount = res.payableAmount;
    }), ((error: any) => {
      console.log(error);
    });
  }

  onContinue() {
    let navigationExtras: NavigationExtras = {
      state: {
        transId: 'giftInformation',
        workQueue: false,
        services: 1,
        code: this.giftInformation
      }
    };

    this.router.navigate(['/send-gift-payment'], navigationExtras);
  }

  onBack() {
    let navigationExtras: NavigationExtras = {
      state: {
        transId: 'giftInformation',
        workQueue: false,
        services: 1,
        code: this.giftInformation,
      }
    };
    this.router.navigate(['/send-gift-information'], navigationExtras);
  }

  setDesign() {
    this.continueBtnColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.skipBtnColor = 'border-color:' + this.getCssWithImportant(this.themeInfo?.base) + '; color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.circleColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base) + '; background-image:' + 'linear-gradient(180deg, transparent 50%, ' + this.themeInfo?.base + ' 50%), linear-gradient(90deg, white 50%, transparent 50%)'
  }

}
