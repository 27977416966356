import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'appc-gifty-app-section',
  templateUrl: './gifty-app-section.component.html',
  styleUrls: ['./gifty-app-section.component.scss']
})
export class GiftyAppSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
