import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { DataService, AppFormComponent, IFieldConfig, FormsService, FieldTypes } from '@app/shared';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'appc-merchant-list',
  templateUrl: './merchant-list.component.html',
  styleUrls: ['./merchant-list.component.scss']
})
export class MerchantListComponent implements OnInit {

  apiBaseUri: any;
  selectedCategoryId: any = 0;

  constructor(private route: ActivatedRoute) {
    this.apiBaseUri = environment.apiUrl;

    this.route.queryParams.subscribe(params => {
      this.selectedCategoryId = params.categoryid;
    });
  }

  ngOnInit() {
  }
}
