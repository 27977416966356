import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { AuthenticationResultStatus, AuthorizeService, DataService, EventManagerService } from '../../services';
import { ApplicationPaths, ReturnUrlType, QueryParameterNames, LoginActions, ApplicationName } from '../../constants';
import { AppFormComponent } from '../forms/components';
import { IFieldConfig, FieldTypes } from '@app/shared/models/forms';
import { FormGroup, Validators } from '@angular/forms';
import { Events } from '@app/shared/models';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';
import { BaseComponent } from '@app/components/base/base.component';
declare var jQuery: any;
// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent extends BaseComponent {
  public message = new BehaviorSubject<string>(null);

  @ViewChild(AppFormComponent, { static: true }) form: AppFormComponent;
  config: IFieldConfig[];

  apiBaseUri: any;

  showPhoneInput: boolean = true;
  showOtp: boolean = false;
  phoneNumber: string;
  otp: any;
  hasError: boolean = false;
  userAgent: any;
  errorMessage: any = "PIN did not match. Check your number or SMS.";
  loggedInUserInfo: any;
  
  //for design
  textColor:string;
  btnColor:string;
  constructor(private authorizeService: AuthorizeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private eventManager: EventManagerService) {
    super();
    this.buildForm();
    if(this.authorizeService.isLoggedIn){
      this.router.navigate(['/']);
    }
    else {
      this.clearStorage();
    }
  }


  setDesign() {
    this.textColor = 'color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.btnColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
  }

  clearStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("consumerId");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("name");
    localStorage.removeItem("profile");
  }

  buildForm() {
    this.config = [
      {
        name: 'phoneNumber',
        //label: "",
        type: FieldTypes.Textbox,
        validation: [Validators.required],
        placeholder: 'Mobile Number',
        showIcon: true,
        srcIcon: 'assets/images/icon-call.png',
        value: ''
      },
      {
        name: 'button',
        label: 'CONTINUE',
        type: FieldTypes.Button,
        cssClass: 'btn-red-cornered form-control lg-red btn-half-block',
        onSubmit: this.onSubmit.bind(this)
      }
    ];
  }

  onSubmit() {
    if (this.form.form.status === "INVALID") return;
    this.phoneNumber = this.form.value.phoneNumber;

    let userInfo = {
      "phoneNumber": this.phoneNumber.replace(' ', '').replace('-', '')
    };

    this.dataService.post('auth/otp', userInfo)
      .subscribe((response: any) => {
        if (response.status) {
          this.showPhoneInput = false;
          this.showOtp = true;
          this.userAgent = response.userAgent;

        } else if (!response.status) {
          this.hasError = true;
          this.errorMessage = response.message;
          return;
        }
      });
  }

  onContinue() {
    var loginModel = {
      "longBirthDate": 0,
      "otp": this.otp,
      "id": 0,
      "device": this.userAgent.userAgent,
      "operatingSystem": this.userAgent.ipAddress,
      "phoneNumber": this.phoneNumber,
      "osVersion": "9",
      "loginApp": 4
    };

    if (loginModel.phoneNumber?.length < 6) {
      this.errorMessage = "PIN did not match. Check your number or SMS.";
      return;
    }

    this.dataService.post('ServiceConsumers', loginModel)
      .subscribe((response: any) => {
        if (response.status) {
          this.loggedInUserInfo = response.data;
          var avatar = "assets/images/user-thumb.png";
          if(this.loggedInUserInfo.imageName != undefined && this.loggedInUserInfo.imageName != null)
            avatar = `${environment.apiUrl}image/profile/consumer/${this.loggedInUserInfo.imageName}`;
          var basicProfile = {"firstName": this.loggedInUserInfo.firstName, "lastName":this.loggedInUserInfo.lastName, "location": this.loggedInUserInfo.location, "birthDate": this.loggedInUserInfo.birthDate, "email": this.loggedInUserInfo.email, "userId": this.loggedInUserInfo.id, avatar : avatar}
          localStorage.setItem("token", response.token);
          localStorage.setItem("consumerId", this.loggedInUserInfo.consumerId);
          localStorage.setItem("name", `${this.loggedInUserInfo.name}`);
          localStorage.setItem("phoneNumber", this.loggedInUserInfo.phoneNumber);
          localStorage.setItem("profile",JSON.stringify(basicProfile))
          this.authorizeService.subscribeLoginEvent();
          this.eventManager.broadcast({ name: Events.loginSuccessful });
          this.router.navigate(['/dashboard']);
        }
        if (!response.status) {
          this.hasError = true;
          localStorage.removeItem("token");
          localStorage.removeItem("consumerId");
          localStorage.removeItem("phoneNumber");
        }
      });
  }

  onOtpChange($event) {
    this.otp = $event;
  }

  onClickBack() {
    this.showPhoneInput = true;
    this.showOtp = false;
  }

  ngOnInit() {
    // this.buildForm();
    const action = this.activatedRoute.snapshot.url[1];
    switch (action.path) {
      case LoginActions.Login:
        //await this.login(this.getReturnUrl());
        this.message.next("login to golp");
        break;
      case LoginActions.LoginCallback:
        //await this.processLoginCallback();
        break;
      case LoginActions.LoginFailed:
        const message = this.activatedRoute.snapshot.queryParamMap.get(QueryParameterNames.Message);
        this.message.next(message);
        break;
      case LoginActions.Profile:
        this.redirectToProfile();
        break;
      case LoginActions.Register:
        this.redirectToRegister();
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }

    setUpDesign();
  }

  private async login(returnUrl: string): Promise<void> {
    const state: INavigationState = { returnUrl };
    const result = await this.authorizeService.signIn(state);
    this.message.next(undefined);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        await this.navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        await this.router.navigate(ApplicationPaths.LoginFailedPathComponents, {
          queryParams: { [QueryParameterNames.Message]: result.message },
        });
        break;
      default:
        throw new Error(`Invalid status result ${(result as any).status}.`);
    }
  }

  private async processLoginCallback(): Promise<void> {
    const url = window.location.href;
    const result = await this.authorizeService.completeSignIn(url);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as completeSignIn never redirects.
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        await this.navigateToReturnUrl(this.getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        this.message.next(result.message);
        break;
    }
  }

  private redirectToRegister(): any {
    this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${ReturnUrlType}=?client_id=${ApplicationName}`);
  }

  private redirectToProfile(): void {
    this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityManagePath}?${ReturnUrlType}=?client_id=${ApplicationName}`);
  }

  private async navigateToReturnUrl(returnUrl: string) {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    await this.router.navigateByUrl(returnUrl, {
      replaceUrl: true,
    });
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (this.activatedRoute.snapshot.queryParams as INavigationState).returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (fromQuery && !(fromQuery.startsWith(`${window.location.origin}/`) || /\/[^\/].*/.test(fromQuery))) {
      // This is an extra check to prevent open redirects.
      throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }
    return (state && state.returnUrl) || fromQuery || ApplicationPaths.DefaultLoginRedirectPath;
  }

  private redirectToApiAuthorizationPath(apiAuthorizationPath: string) {
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser they get sent back to where it was on the app instead of to an endpoint on this
    // component.
    window.location.replace(apiAuthorizationPath);
  }
}



interface INavigationState {
  [ReturnUrlType]: string;
}

function setUpDesign() {
  (function ($) {
    $(document).ready(function () { 
        setDesign();
    });

    function setDesign() {

      let baseColor = JSON.parse(localStorage.getItem('themeInfo'))?.base;
      if(baseColor != undefined) {
        $("button").attr("style", "background-color:" + baseColor +" !important")
        $(".form-control:valid").css("border-color", baseColor);
        $("input").keyup(function(){
          $(".is-valid").css("border-color", baseColor);
          $(".form-control:valid").css("border-color", baseColor);
        });
        $("input").change(function(){
          $(".is-valid").css("border-color", baseColor);
          $(".form-control:valid").css("border-color", baseColor);
        });
        $("input").click(function(){
          $(".is-valid").css("border-color", baseColor);
          $(".form-control:valid").css("border-color", baseColor);
        });
        
      }
     
    }
  })(jQuery);
}
