import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NavigationExtras, Router } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
declare var jQuery: any;

@Component({
  selector: 'appc-popular-gift',
  templateUrl: './popular-gift.component.html',
  styleUrls: ['./popular-gift.component.scss']
})
export class PopularGiftComponent extends BaseComponent {

  @Input() popularGifts: any[];

  popularGiftsData: any[];
  apiBaseUri: any;
  viewAllBtnColor:string;

  constructor(private router: Router) {
    super();
    this.apiBaseUri = environment.apiUrl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['popularGifts']) {
      this.popularGiftsData = this.popularGifts;
    }
  }

  ngOnInit() {
    configureSlick();
  }
  
  setDesign() {
    this.viewAllBtnColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
  }
  onSendPopularGift(item: any) {

    let giftInfo = {
      "id": item.merchantId,
      "name": item.merchant,
      "companyLogoUrl": item.merchantLogoUrl,
      "giftAmount": item.offerPrice,
      "isPopularGift": true
    };

    let navigationExtras: NavigationExtras = {
      state: {
        transd: 'giftInformation',
        workQueue: false,
        services: 1,
        code: giftInfo
      }
    };

    this.router.navigate(['send-gift-information'], navigationExtras);
  }

  onViewAllPopularGifts() {
    this.router.navigate(['popular-gifts']);
  }

}

function configureSlick() {
  (function ($) {
    let baseColor = JSON.parse(localStorage.getItem('themeInfo'))?.base;
    $(document).ready(function () {
      
      $('.popular-list').not('.slick-initialized').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: true,
        //variableWidth: true,
        centerPadding: "0px",
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              variableWidth: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              variableWidth: true
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ],
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-right"></i></button>'
      });
      
      if(baseColor != undefined) {
        baseColor += ' !important'
      //   $('.pricing-label').attr("style", "color : " + baseColor);
      //   $('.popular-items-inside').attr("style", "border-bottom-color : " + baseColor);
      //   $('#popular-gift > .slick-dots .slick-active button').attr("style", "background : " + baseColor)
      //   $('#popular-gift > .slick-dots > li > button').click(function (e) { 
      //     $('#popular-gift > .slick-dots > li > button').attr("style", "background : #D4D4D4");
      //     $(this).attr("style","background : " + baseColor);
      // }); 
      }
      
    });
    
  })(jQuery);
}
