import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';

@Component({
  selector: 'appc-my-privilege-card-facilities',
  templateUrl: './my-privilege-card-facilities.component.html',
  styleUrls: ['./my-privilege-card-facilities.component.scss']
})
export class MyPrivilegeCardFacilitiesComponent extends BaseComponent implements OnInit {
  privilegeCard: any;
  cardFacilities: any[] = [];
  cardName:string;

  currentConditions:any;

  seeMoreEnable:boolean = false;
  //test

  constructor(private router: Router) { 
    super();
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if(state.code != undefined && state.code != null){
      this.cardFacilities = state.code.cardFacilities;
      this.cardName = state.code.cardName;
    }
  }

  ngOnInit() {
  }

  toggleInfo(index){
    this.cardFacilities[index].show = !this.cardFacilities[index].show;
  }

  setCondition(conditions){
    this.currentConditions = conditions;
  }


  getDiscountString(input){
    if(input.valueType == "Percentage"){
      return `${input.value}% ${input.facilityType}`
    }
    else {
      return `BDT ${input.value} ${input.facilityType}`
    }
  }

  checkLineLength(item){
    if(item.length > 27){
      this.seeMoreEnable = true;
    }
    return true;
  }

}
