import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { DataService } from '@app/shared';
import { MerchantOutletResponse } from '@app/shared/models/view-models/merchant-outlets-response';
import { AlertifyService } from '@app/shared/services/alertify.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'appc-gift-details',
  templateUrl: './gift-details.component.html',
  styleUrls: ['./gift-details.component.scss']
})
export class GiftDetailsComponent extends BaseComponent implements OnInit {

  giftId: any;
  giftType: any;
  giftDetails: any;
  giftSender: any;
  apiBaseUri: any;
  merchentId: any;
  availInstruction: any;
  instructionCollapse: boolean = false;
  instructionExpand: boolean = true;
  qrResultString: any;
  showCode: boolean = false;
  giftCode: any = "XUIA-809T-PYAI-0178";
  giftPin: any = "123456";
  qrIsOpened = false;
  navigationData: any;
  merchantOutlets: MerchantOutletResponse[] = [];
  qrCodeInfo: any;
  qrPin: any;
  color: string;
  backgroundColor: string;
  constructor(private route: ActivatedRoute, private dataService: DataService, private router: Router, private alert: AlertifyService) {
    super();
    this.apiBaseUri = environment.apiUrl;

    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };
    if (state !== undefined && state.code !== undefined) {
      this.navigationData = state.code;
      if (this.navigationData != null) {
        this.qrCodeInfo = this.navigationData.qrCode;
      }
    }
  }


  setDesign() {
    this.color = 'color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.backgroundColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
  }
  goForAvailProcess() {
    let url = `offerassignments/mark-avail`;
    let consumerId = localStorage.getItem('consumerId');
    let payload = {
      id: this.giftDetails?.id,
      outletQRCode: this.qrCodeInfo,
      merchantId: this.merchentId,
      offerType: Number(this.giftType),
      consumerId: consumerId,
      offerPrice: this.giftDetails?.offerPrice,
      offerId: this.giftDetails?.offerId,
      offerAvailStatus: 1,
      isApproved: this.giftDetails?.isApproved,
      hasConsumed: this.giftDetails?.hasConsumed,
      phoneNumber: this.giftDetails?.phoneNumber,
      offerAvailMethod: this.giftDetails?.offerAvailMethod,
      outletPhoneNumber: this.giftDetails?.outletPhoneNumber,
    };
    this.dataService.put(url, payload).subscribe((response: QrCodeResponse) => {
      // console.log("init avail process : " + response);
      // console.log(JSON.stringify(response));
      if (!response.status)
        this.alert.confirm(response.message, () => { })
      else {
        this.qrPin = response.golpCouponCode;
      }

    }, err => console.log(err));
  }

  ngOnInit() {
    this.giftId = this.route.snapshot.params.giftId;
    this.giftType = this.route.snapshot.params.giftType;
    this.merchentId = this.giftDetails?.merchantId;
    this.getGiftDetails();

  }

  getGiftDetails() {
    var url = `offerassignments/offer-type/${this.giftType}/${this.giftId}`;
    var giftId = Number(this.giftId);
    if (giftId)
      url = `consumer-offer/${localStorage.getItem("consumerId")}/offer/${this.giftId}`;
    this.dataService.get(url).subscribe((response: any) => {
      this.giftDetails = response;
      console.log(this.giftDetails);
      this.giftSender = this.giftDetails?.companyProvider != null ? this.giftDetails?.companyProvider : this.giftDetails?.consumerProvider
      this.merchentId = this.giftDetails?.merchantId;
      this.availInstruction = this.giftDetails?.availInstruction;
      console.log(this.giftDetails);
      if (this.merchentId != null && this.merchentId > 0)
        this.getSelectedMerchantOutlets();

      if (this.qrCodeInfo != null)
        this.goForAvailProcess();
    });
  }

  isExpired() {
    var expirationDate = new Date(this.giftDetails?.expirationDate);
    if (expirationDate)
      return expirationDate < new Date();

    return true;
  }
  getSelectedMerchantOutlets() {
    let url = `Outlets/company/${this.merchentId}`;
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
      }
    });

  }

  hasResendAccess() {
    let currentUser = localStorage.getItem('consumerId');
    return this.giftDetails && this.giftDetails?.ownedByConsumer != undefined && this.giftDetails?.ownedByConsumer != null && this.giftDetails?.ownedByConsumer == currentUser;
  }


  resendGift() {
    let giftInfo = {
      "id": this.giftDetails.id,
      "name": this.giftDetails.merchant,
      "companyLogoUrl": this.giftDetails.merchantLogoUrl,
      "giftAmount": this.giftDetails.offerPrice,
      "isResendGift": true,
      "giftAssignmentId": this.giftDetails.giftAssignmentId
    };

    let navigationExtras: NavigationExtras = {
      state: {
        transd: 'giftDetailsForResend',
        workQueue: false,
        services: 1,
        code: giftInfo
      }
    };

    this.router.navigate(['send-gift-information'], navigationExtras);
  }

  veiwCode() {
    let url = `offerassignments/view-gift-code/${this.giftId}`
    this.dataService.get(url).subscribe((response: any) => {
      if (response.success) {
        this.giftCode = response.data?.giftCode;
        this.giftPin = this.giftDetails?.offerAvailMethod == "GiftyCouponCode" ? "No PIN Required" : response.data?.giftPin;
        this.showCode = true;
      }
    });
  }

  onCodeResult(result) {
    this.qrResultString = result;
  }

  openQR() {
    var navigationData = new QRCodeNavigationData();
    navigationData.url = this.router.url;
    let navigationExtras: NavigationExtras = {
      state: {
        transd: 'giftDetails',
        workQueue: false,
        services: 1,
        code: navigationData,
      }
    };

    this.router.navigate(['scan-qrcode'], navigationExtras);
  }

  clearResult() {

  }

  selectMerchant() {
    let navigationExtras: NavigationExtras = {
      state: {
        transd: 'giftDetails',
        workQueue: false,
        services: 1,
        code: this.giftDetails,
      }
    };

    this.router.navigate([`gifts/${this.giftType}/${this.giftId}/selectmerchant`], navigationExtras);
  }

  hideCode() {
    this.showCode = false;
  }

  expandCollapse() {
    this.instructionCollapse = this.instructionCollapse ? false : true;
    this.instructionExpand = this.instructionExpand ? false : true;
  }
}

export class QRCodeNavigationData {
  url: string;
}

export interface QrCodeResponse {
  data: QrCodeResponseData;
  status: boolean;
  message: string;
  golpCouponCode: null;
  offerAvailStatus: number;
}

export interface QrCodeResponseData {
  id: string;
  phoneNumber: string;
  consumerId: string;
  offerId: number;
  offerPrice: number;
  expirationDate: null;
  isApproved: boolean;
  hasConsumed: boolean;
  offerType: number;
  merchantId: number;
  outletQRCode: string;
  offerAvailMethod: string;
  alternateAvailMethod: null;
  outletPhoneNumber: null;
  offerAvailStatus: number;
  otherPhoneNumber: null;
  operator: number;
  connectionType: number;
}

