import { Component } from '@angular/core';

import { FieldBaseComponent } from '../field-base';

@Component({
  selector: 'app-form-date',
  styleUrls: ['form-date.component.scss'],
  templateUrl: 'form-date.component.html',
})
export class FormDateComponent extends FieldBaseComponent {
  getClass(cls: String){
    return cls + " " + this.config.cssClass;
  }

  getCurrentDate(){
    return new Date();
  }
}
