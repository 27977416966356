import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '@app/shared/services';

@Component({
  selector: 'appc-sent-gift-details',
  templateUrl: './sent-gift-details.component.html',
  styleUrls: ['./sent-gift-details.component.scss']
})
export class SentGiftDetailsComponent implements OnInit {

  isDelivered: boolean;
  giftId: any;
  deliveredGift: any;

  constructor(private dataService: DataService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.giftId = this.route.snapshot.params.giftId;
    this.getSendGiftInformation();
  }

  getSendGiftInformation() {
    let url = `consumer-offer/${localStorage.getItem("consumerId")}/offer/${this.giftId}`;
    this.dataService.get(url).subscribe((response: any) => {
      this.deliveredGift = response;
      console.log(this.deliveredGift);
    });
  }

}
