import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
declare var jQuery: any;

@Component({
  selector: 'appc-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.scss']
})
export class MerchantComponent implements OnInit {

  @Input() trendingMerchants: any[];

  trendingMerchantsData: any[];
  //trendingMerchantsDataOdd: any[];
  //trendingMerchantsDataEven: any[];
  apiBaseUri: any;

  constructor(private router: Router) {
    this.apiBaseUri = environment.apiUrl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['trendingMerchants']) {
      this.trendingMerchantsData = this.trendingMerchants;
      //this.trendingMerchantsDataOdd = this.trendingMerchants.filter((v, i) => !(i % 2));
      //this.trendingMerchantsDataEven = this.trendingMerchants.filter((v, i) => i % 2);
      //
    }
  }

  ngOnInit() {
    (function ($) {
      $(document).ready(function () {
        $('.merchants-list').slick({
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 0,
          centerMode: true,
          //variableWidth: true,
          centerPadding: "0px",
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                variableWidth: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                variableWidth: true
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true
              }
            }
          ],
          prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-right"></i></button>'
        });
      });
    })(jQuery);
  }

  viewMerchants() {
    this.router.navigate(['/merchants']);
  }
}
