import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@app/shared';

@Component({
  selector: 'appc-outlets',
  templateUrl: './outlets.component.html',
  styleUrls: ['./outlets.component.scss']
})
export class OutletsComponent implements OnInit {

  merchantId:number = 0;
  outlets:any;
  constructor( private route: ActivatedRoute, private dataService:DataService) { }

  ngOnInit() {
    this.merchantId = this.route.snapshot.params.merchantId;
    if(this.merchantId > 0)
      this.getOutlets();
  }


  getOutlets(){
    let url = `outlets/company/${this.merchantId}`
    this.dataService.get(url).subscribe(response=>{
      this.outlets = response;
    }, err=>console.log(err));
  }
}
