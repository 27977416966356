import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'appc-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  
  themeInfo: any;

  constructor() { 
    this.themeInfo = this.getThemeInfo();
    this.setDesign();
  }

  setDesign(){}
  ngOnInit() {
  }

  getThemeInfo(): any {
    let theme = localStorage.getItem('themeInfo')
    if(theme != undefined && theme != null){
      return JSON.parse(theme);
    }
    return null;
  }

  getCssWithImportant(info:string) {
    return `${info} !important`;
  }

}
