import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { share, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventManagerService {

  observable: Observable<any>;
  observer: Observer<any>;

  constructor() {
    this.observable = new Observable((observer: any) => {
      this.observer = observer;
    }).pipe(share());
  }

  subscribe(eventName: any, callback: any) {
    const subscriber: Subscription = this.observable
      .pipe(
        filter(event => {
          return event.name == eventName;
        })
      ).subscribe(callback);

    return subscriber;
  }

  broadcast(event: any) {
    if (this.observer != null) {
      this.observer.next(event);
    }
  }

  destroy(subscriber: Subscription) {
    subscriber.unsubscribe();
  }
}
