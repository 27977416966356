import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { DataService } from '.';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private supportedImageFile: string[] = ['image/jpeg','image/png'];
  private accessToken:any;

  constructor(private http: HttpClient) {
    this.accessToken = localStorage.getItem("token");
   }

  imageFileUpload(files: any, filePropertyName: string):any{
    let fileValidationError = null;
    let fileName;
    if (files.length === 0)
      fileValidationError = "Empty file";

    if(files[0].size > (1024*1024)){
      fileValidationError = "Maximum file size should be 1 MB";
    }
    
    let imageType = files[0].type;
    let validImage = this.supportedImageFile.filter(c => c == imageType)[0];
    if(validImage == null){
      fileValidationError = "Only JPG/PNG files are supported";
    }

    const formData = new FormData();

    for (let file of files){
      formData.append(filePropertyName, file);
      fileName = file.name;
    }

    return { validationError: fileValidationError, fileName: fileName, formData: formData };
  }

  uploadProfilePic(uploadedFileData: FormData, consumerId: any) {
    var requestHeader= new HttpHeaders({
      enctype: "multipart/form-data",
      Authorization: `Bearer ${this.accessToken}`
    });

    var requestParams = new HttpParams();
    requestParams.set('consumerId', consumerId);

    return this.http.post(
      `${environment.apiUrl}api/ServiceConsumers/upload-profile-picture?consumerId=${consumerId}`,
      uploadedFileData,
      {
        headers:requestHeader,
        // params: requestParams
      }
    );
  }
}
