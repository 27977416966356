import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsService, AppFormComponent, IData, DataService, AppService } from '@app/shared';
import { IFieldConfig, FieldTypes } from '@app/models';
import { Router, NavigationExtras } from '@angular/router';
import { environment } from 'environments/environment';
import { stat } from 'fs';
import { AlertifyService } from '@app/shared/services/alertify.service';
import { FileUploadService } from '@app/shared/services/file-upload.service';
import { HttpHeaders } from '@angular/common/http';
import { BaseComponent } from '../base/base.component';


@Component({
  selector: 'appc-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseComponent implements OnInit {

  @ViewChild(AppFormComponent, { static: true }) form: AppFormComponent;
  @ViewChild('imageFile') imageFile: ElementRef<HTMLElement>;

  config: IFieldConfig[];

  apiBaseUri: any;
  fullName: String;
  userPhoneNumber: String;
  profileInfoForm: FormGroup;
  firstName:String;
  lastName:String;
  dob:String;
  email: String;
  location: String;
  consumerId:String;
  accessToken: String;
  userId:String;
  imagePreviewPath: any = "assets/images/user-thumb.png";
  fileValidationError: string;
  fileName: String;
  uploadedFiles: FormData;
  isNewImageSelected: boolean = false;
  color:string;
  backgroundColor:string;
  constructor(private formBuilder: FormBuilder, private router: Router, private alertifyService: AlertifyService, private dataService: DataService, private appService: AppService, private fileUploadService: FileUploadService) {
    super();
    this.apiBaseUri = environment.apiUrl;
    this.fullName = localStorage.getItem('name');
    this.userPhoneNumber = localStorage.getItem('phoneNumber');
    
    this.consumerId = localStorage.getItem('consumerId');
    this.accessToken = localStorage.getItem('token');
    this.loadProfileInfo();
  }

  ngOnInit() {
    
    this.buildForm();
  }
  setDesign() {
    this.color = 'color:' + this.getCssWithImportant(this.themeInfo ?.base);
    this.backgroundColor = 'background-color:' + this.getCssWithImportant(this.themeInfo ?.base);
  }
 loadProfileInfo(){
   var basicProfile = JSON.parse(localStorage.getItem('profile'));
   if(basicProfile != undefined && basicProfile != null){
     this.firstName = basicProfile.firstName;
     this.lastName = basicProfile.lastName;
     this.email = basicProfile.email;
     this.location = basicProfile.location;
     this.dob = basicProfile.birthDate;
     this.userId = basicProfile.userId;
     this.imagePreviewPath = (basicProfile.avatar == undefined || basicProfile.avatar == null) ?"assets/images/user-thumb.png":basicProfile.avatar;
     this.appService.profileImageUpdate$.next(this.imagePreviewPath);

   }
   
  this.firstName = basicProfile.firstName;
 }

  buildForm() {
    this.config = [
      {
        name: 'firstName',
        label: "First Name",
        type: FieldTypes.Textbox,
        validation: [Validators.required],
        placeholder: 'John',
        showIcon: true,
        srcIcon: 'assets/images/icon-person.png',
        value: this.firstName
      },
      {
        name: 'lastName',
        label: "Last Name",
        type: FieldTypes.Textbox,
        validation: [Validators.required],
        placeholder: 'Doe',
        showIcon: true,
        srcIcon: 'assets/images/icon-person.png',
        value: this.lastName
      },
      {
        name: 'email',
        label: "Email Address",
        type: FieldTypes.Email,
        validation: [Validators.nullValidator],
        placeholder: 'john.doe@example.com',
        showIcon: true,
        srcIcon: 'assets/icons/icon-email.png',
        value: this.email,
        cssIcon:'email'
      },
      {
        name: 'dob',
        label: "Date of Birth",
        type: FieldTypes.Date,
        validation: [Validators.nullValidator],
        placeholder: 'Date of Birth',
        showIcon: true,
        srcIcon: 'assets/images/icon-calendar.png',
        value: this.dob,
        cssIcon:'dob'
      },
      {
        name: 'location',
        label: "Address Line",
        type: FieldTypes.Textbox,
        validation: [Validators.nullValidator],
        placeholder: 'Address Line',
        showIcon: true,
        srcIcon: 'assets/icons/icon-location.png',
        value: this.location
      },
      
      // {
      //   name: 'button',
      //   label: 'BACK',
      //   type: FieldTypes.Button,
      //   cssClass: 'btn-red-cornered form-control lg-red btn-half-block btn-flat-putlined hiddem-above-md  btn-normal btn-back',
      //   onSubmit: this.onBack.bind(this)
      // },
      // {
      //   name: 'button',
      //   label: 'CONTINUE',
      //   type: FieldTypes.Button,
      //   cssClass: 'btn-red-cornered form-control lg-red btn-half-block btn-continue',
      //   onSubmit: this.onSubmit.bind(this)
      // }
    ];

  }
  openFileBrowser(event: any) {
    event.preventDefault();
    let imageFile: HTMLElement = this.imageFile.nativeElement;
    imageFile.click();
  }

  onUploadPhoto(files) {
    this.fileValidationError = null;
    let fileInfo = this.fileUploadService.imageFileUpload(files, "file");
    if (fileInfo.validationError != null) {
        this.fileValidationError = fileInfo.validationError;
        return;
    }
    this.fileName = fileInfo.fileName;
    this.uploadedFiles = fileInfo.formData;

    var reader = new FileReader();
    reader.onload = (event: any) => {
        this.imagePreviewPath = event.target.result;
    }
    reader.readAsDataURL(files[0]);
    this.isNewImageSelected = true;
  }

  

  // convenience getter for easy access to form fields
  get f() { return this.profileInfoForm.controls; }

  onSubmit() {
    if (this.form.form.status === "INVALID") {
      this.form.form.markAllAsTouched();
      return;
    }

    let data = this.form.value;
    
    let reqHeader = {
      "firstName": data.firstName,
      "lastName": data.lastName,
      "email": data.email,
      "consumerId": this.consumerId,
      "location": data.location,
      "accessToken": this.accessToken,
      "birthDate": data.dob,
      "id": this.userId,
      "fullName": `${data.firstName} ${data.lastName}`,
    }
    ///post to server

    let updateProfileUrl = `ServiceConsumers/${this.userId}`;

    if(this.isNewImageSelected){
      this.uploadedFiles.append('file', data.id)
      this.fileUploadService.uploadProfilePic(this.uploadedFiles, this.consumerId).subscribe((result: any) => {
        let avatar = `${environment.apiUrl}${result.picturePath.replace(/\\/g, "/")}`
        this.dataService.put(updateProfileUrl, reqHeader).subscribe((response: any) => {
          var loggedInUserInfo = response;
          if(loggedInUserInfo.id > 0){
            if (this.uploadedFiles != null) {
              this.alertifyService.success("Profile updated successfully");
              var basicProfile = {"firstName": loggedInUserInfo.firstName, "lastName":loggedInUserInfo.lastName, "location": loggedInUserInfo.location, "birthDate": loggedInUserInfo.birthDate, "email": loggedInUserInfo.email, "userId": loggedInUserInfo.id, "avatar": avatar}
              localStorage.setItem("name", `${loggedInUserInfo.fullName}`);
              localStorage.setItem("profile",JSON.stringify(basicProfile))
              this.appService.profileNameUpdate$.next(loggedInUserInfo.fullName);
              this.appService.profileImageUpdate$.next(avatar);
            }
            
          }
          else
            this.alertifyService.error("Profile update failed, please try again")
        }, (err: any)=>{
          console.log(err);
          this.alertifyService.error("Profile update failed, please try again")
        });
      },
      (error: any) => {
        console.log(error);
          this.alertifyService.error("Profile update failed, please try again")
        
      });
    }else {
      this.dataService.put(updateProfileUrl, reqHeader).subscribe((response: any) => {
        var loggedInUserInfo = response;
        if(loggedInUserInfo.id > 0){

            this.alertifyService.success("Profile updated successfully");
            this.fullName = loggedInUserInfo.fullName;
            var parseBasicProfile = JSON.parse(localStorage.getItem('profile'));
            var basicProfile = {"firstName": loggedInUserInfo.firstName, "lastName":loggedInUserInfo.lastName, "location": loggedInUserInfo.location, "birthDate": loggedInUserInfo.birthDate, "email": loggedInUserInfo.email, "userId": loggedInUserInfo.id, "avatar": parseBasicProfile.avatar}
            localStorage.setItem("name", `${loggedInUserInfo.fullName}`);
            localStorage.setItem("profile",JSON.stringify(basicProfile))
            this.appService.profileNameUpdate$.next(loggedInUserInfo.fullName);
        }
        else
          this.alertifyService.error("Profile update failed, please try again")
      }, (err: any)=>{
        this.alertifyService.error("Profile update failed, please try again")
      });
    }
    this.isNewImageSelected= false;
  }

}
