import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@app/shared';
import { AlertifyService } from '@app/shared/services/alertify.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'; 
import { MessageModalController } from '@app/components/shared/message-modal/message-modal-controller';

@Component({
  selector: 'appc-gift-merchant-selection',
  templateUrl: './gift-merchant-selection.component.html',
  styleUrls: ['./gift-merchant-selection.component.scss']
})
export class GiftMerchantSelectionComponent implements OnInit {
  giftId: any;
  giftType: any;
  selectedMerchantId: any;
  isMerchantSelected: boolean = false;
  assignMerchantResponse: any;

  giftDetails: any;


  constructor(private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private alertService: AlertifyService,
    private dialog: MatDialog) {
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };
    if (state !== undefined && state.code !== undefined) {
      this.giftDetails = state.code;
    }
  }

  ngOnInit() {
    this.giftId = this.route.snapshot.params.giftId;
    this.giftType = this.route.snapshot.params.giftType;
  }

  getDenomination(amountInfo) {
    const detonations = amountInfo.slabs;
    let message = '';
    for(let i = 0; i < detonations.length; ++i) {
      if(i != detonations.length -1) {
        message = `${message} BDT ${detonations[i]}/-`
      }
      else {
        message = `${message} and BDT ${detonations[i]}/-`
      }
    } 
    return message;
  }

  getDenominationMessage(info) {
    console.log('ger', info);
    const message = `Please note that ${info.name} denominations are available in ${this.getDenomination(JSON.parse(info.companySettings.GiftAmount))} only.`;
    return message;
  }

  onSelectedMerchant($event: any) {
    if ($event != null) {

      let giftCardSettings = JSON.parse($event.companySettings?.GiftAmount);

      if (giftCardSettings.useSlab) {
        if (giftCardSettings.slabs.includes(this.giftDetails?.offerPrice)) {
          this.selectedMerchantId = $event.id;
          this.isMerchantSelected = true;
        }
        else {
          this.selectedMerchantId = null;
          this.isMerchantSelected = false;
          console.log($event.name);
          console.log($event);
          const message = this.getDenominationMessage($event);
          //this.alertService.error(message);
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {message: message, contact: 'For further assistance, call our support at'};
          const confirmationDialog = this.dialog.open(
            MessageModalController,
            dialogConfig
          );

          confirmationDialog.afterClosed().subscribe();
        }
      }
      else {
        this.selectedMerchantId = $event.id;
        this.isMerchantSelected = true;
      }
    }
    else {
      this.selectedMerchantId = null;
      this.isMerchantSelected = false;
    }

  }

  onSkip() {
    this.router.navigate([`gifts/${this.giftType}/${this.giftId}`]);
  }

  onContinue() {
    let assignMerchantUrl = "offerassignments/assign-merchant";
    let reqHeader = {
      "giftAssignmentId": this.giftId,
      "merchantId": this.selectedMerchantId
    }
    this.dataService.post(assignMerchantUrl, reqHeader).subscribe((response: any) => {
      this.assignMerchantResponse = response;
      if (response.success)
        this.router.navigate([`gifts/${this.giftType}/${this.giftId}`]);
    });
  }
}
