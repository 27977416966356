import { DOCUMENT } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { DataService } from '@app/shared/services/data.service';
import { environment } from 'environments/environment';
import * as $ from 'jquery';
declare let bKash: any;

@Component({
  selector: 'appc-gift-payment',
  templateUrl: './gift-payment.component.html',
  styleUrls: ['./gift-payment.component.scss']
})
export class GiftPaymentComponent extends BaseComponent {

  giftInformation: any;
  giftCreationResponse: any;
  payableAmount: any;
  giftId: any;
  paymentInformation: any = {};
  selectedPaymentMethod: number = 1;
  agent: string = 'golp';
  isProduction: boolean = false;

  //for design
  skipBtnColor: string;
  continueBtnColor: string;
  amountTxtColor: string;
  circleColor: string;

  //bkash script
  private script1: any;
  private script2: any;
  constructor(private router: Router,
    private dataService: DataService, private renderer2: Renderer2, @Inject(DOCUMENT) private document) {
    super();
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state != undefined && state.code != undefined) {
      this.giftInformation = state.code;
      console.log(this.giftInformation);
    }

    // this.addBkashScript();
  }

  ngOnInit() {
    this.agent = environment.agent;
    this.isProduction = environment.production;
  }

  ngAfterViewInit(): void {
    this.addBkashScript();
    this.createGift();
  }

  ngOnDestroy() {
    // this removes the script so it won't be added again when the component gets initialized again.
    this.renderer2.removeChild(this.document.body, this.script1)
    this.renderer2.removeChild(this.document.body, this.script2)
  }

  addBkashScript() {
    this.script1 = this.renderer2.createElement('script');
    this.script1.type = `text/javascript`;
    if (this.isProduction) {
      this.script1.src = `https://scripts.pay.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout.js`;
    }
    else {
      this.script1.src = `https://scripts.sandbox.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout-sandbox.js`;
    }
    this.script1.onload = this.doneCheckoutLoading;
    this.renderer2.appendChild(this.document.body, this.script1);

    this.script2 = this.renderer2.createElement('script');
    this.script2.type = `text/javascript`;
    this.script2.src = `https://capp-cdn.labs.bka.sh/scripts/webview_bridge.js`;
    this.script2.onload = this.doneWebBridgeLoading;
    this.renderer2.appendChild(this.document.body, this.script2);
  }

  doneCheckoutLoading() { }

  doneWebBridgeLoading() { }

  setDesign() {
    this.continueBtnColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.skipBtnColor = 'border-color:' + this.getCssWithImportant(this.themeInfo?.base) + '; color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.amountTxtColor = 'color: ' + this.getCssWithImportant(this.themeInfo?.base);
    this.circleColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
  }

  goPay() {

    let paymentData = this.giftCreationResponse;
    let consumerId = localStorage.getItem("consumerId");

    let paymentCreateRequest = {
      amount: paymentData.amount,
      consumerId: consumerId,
      giftId: paymentData.consumerOfferDto?.id,
      resendRequestId: paymentData.consumerOfferDto?.resendRequestId,
      sessionType: 'ConsumerGift', // consumer gift
      isDataEncrypted: false,
      assignmentId: paymentData.referenceAssignmentId,
      PaymentInitiatePoint: 3,
      orderId: paymentData.orderId
    };

    this.dataService.post('payment/ssl-gateway-payment', paymentCreateRequest).subscribe((data: any) => {
      console.log(data);
      if (data.status == "SUCCESS") {
        console.log("Payment session successfully created");
        window.location.href = data.gatewayPageURL;
      } else {
        console.log("Failed to create Payment session.");
        $("div#pBar").hide();
      }
    },
      (error: any) => {
        console.log(error);
      }
    );

  }

  changePaymentMethod(selectedPaymentMethod: number) {
    this.selectedPaymentMethod = selectedPaymentMethod;
  }

  createGift() {
    if (this.giftInformation) {
      let giftData = {
        "offerPrice": this.giftInformation.giftAmount,
        "ownedByConsumer": localStorage.getItem("consumerId"),
        "merchantId": this.giftInformation.merchantId,
        "phoneNumbers": this.giftInformation.giftReceiver,
        "offerOccasion": this.giftInformation.message,
        "golpPaymentMode": 2,
        "giftDeliveryMethod": 1,

        // change with real data
        "haveDiscountCode": this.giftInformation.hasDiscount,
        "discountAmount": Number(this.giftInformation.giftAmount) - this.giftInformation.discountAmount,
        "resellerPartnerId": "FFC7C23B-3401-409A-AD46-65C18AEB9CCE"
      };

      this.dataService.post("consumer-offer", giftData)
        .subscribe((response: any) => {
          this.giftCreationResponse = response;
          this.payableAmount = this.giftCreationResponse.amount;
          this.giftId = this.giftCreationResponse.referenceAssignmentId;
          if (this.selectedPaymentMethod == 1)
            this.bKashInitialize();
        });
    }
  }

  onBack() {
    let navigationExtras: NavigationExtras = {
      state: {
        transd: 'giftInformation',
        workQueue: false,
        services: 1,
        code: this.giftInformation
      }
    };
    this.router.navigate(['/send-gift-confirmation'], navigationExtras);
  }

  bKashInitialize() {
    // state data
    let navigationExtras: NavigationExtras = {
      state: {
        transd: 'giftInformation',
        workQueue: false,
        services: 1,
        code: this.giftInformation
      }
    };

    let paymentData = this.giftCreationResponse;
    let consumerId = localStorage.getItem("consumerId");
    var paymentID;
    let bKashPaymentService = this.dataService;
    let router = this.router;

    let paymentCreateRequest = {
      amount: paymentData.amount,
      consumerId: consumerId,
      giftId: paymentData.consumerOfferDto?.id,
      resendRequestId: paymentData.consumerOfferDto?.resendRequestId,
      sessionType: 'ConsumerGift', // consumer gift
      isDataEncrypted: false,
      assignmentId: paymentData.referenceAssignmentId,
      bKashMerchantAccount: 1,
      PaymentInitiatePoint: 3,
      orderId: paymentData.orderId
    };

    bKash.init({
      paymentMode: 'checkout',
      paymentRequest: paymentCreateRequest,
      createRequest: function () {
        console.log('=> createRequest (request) :: ');
        paymentCreateRequest.amount = paymentData?.amount;

        bKashPaymentService.post(`payment/init-bkash-payment`, paymentCreateRequest)
          .subscribe((data: any) => {
            if (data != null && data.paymentID != null) {
              paymentID = data.paymentID;
              paymentCreateRequest.amount = data.amount;
              bKash.create().onSuccess(data);

            } else {
              bKash.create().onError();
              // show the erro in alert
              router.navigate([`/send-gift-delivered/false/${data.errorMessage}`], navigationExtras);
            }
          }, (error: any) => {
            console.log(error);
            bKash.create().onError();
            router.navigate(['/send-gift-delivered'])
          });
      },
      executeRequestOnAuthorization: function () {
        console.log('=> executeRequestOnAuthorization');
        let paymentExecuteRequest = {
          paymentID: paymentID,
          offerId: paymentCreateRequest.giftId,
          sessionType: paymentCreateRequest.sessionType,
          assignmentId: paymentCreateRequest.assignmentId,
          isDataEncrypted: false,
          bKashMerchantAccount: 1,
          orderId: paymentCreateRequest.orderId
        };

        bKashPaymentService.post(`payment/execute-bkash-payment`, paymentExecuteRequest)
          .subscribe((data: any) => {
            if (data != null && data.paymentID != null) {
              router.navigate([`/send-gift-delivered/true/${paymentExecuteRequest.offerId}`]);

            } else {
              bKash.create().onError();
              // show the error message in alert
              router.navigate([`/send-gift-delivered/false/${data.errorMessage}`], navigationExtras);
            }
          }, (error: any) => {
            console.log(error);
            bKash.create().onError();
            router.navigate([`/send-gift-delivered/false/server-error`], navigationExtras)
          });
      },
      onClose: function () {
        console.log('user close');
        //window.location.reload();
      }
    });
  }

}
