import { Component, Inject, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DataService } from '@app/shared';

@Component({
  selector: 'appc-my-privilege-card',
  templateUrl: './my-privilege-card.component.html',
  styleUrls: ['./my-privilege-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyPrivilegeCardComponent implements OnInit {
  item: any;
  apiBaseUri: any;
  receivedData: any;
  constructor(@Inject('API_BASE_URL') private BASE_URL: string, private router: Router, private dataService: DataService) {
    this.loadCards();
  }

  ngOnInit() {
  }

  getUserPhone() {
    let phoneNo = localStorage.getItem("phoneNumber");
    return phoneNo;
  }

  getImageBackground() {
    let url = `assets/images/privilege-card-back.png`
    return url;
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  itemClicked(item: any) {
    let cardFacilities = item.cardFacilities;
    let card = {
      "cardFacilities": cardFacilities,
      "cardName": item.cardName
    }
    let navigationExtras: NavigationExtras = {
      state: {
        transId: 'cardFacilities',
        workQueue: false,
        services: 1,
        code: card,
      }
    };

    this.router.navigate(['privilege-card/card-facilities'], navigationExtras);
  }

  loadCards() {
    let phone = this.getUserPhone();

    let url = `CardBeneficiary/my-card/${phone}`;
    this.dataService.get(url).subscribe((response: any) => {

      this.receivedData = response;
      if (!response.success) {
        this.receivedData.data = [];
      }
    }, err => console.log(err));
  }


  makeProperCase(txt) {
    return txt.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
  };
}
