import { Component, OnInit } from '@angular/core';
import { DataService } from '@app/shared/shared.module';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'appc-popular-gift-list',
  templateUrl: './popular-gift-list.component.html',
  styleUrls: ['./popular-gift-list.component.scss']
})
export class PopularGiftListComponent implements OnInit {

  popularGiftsData: any[];
  apiBaseUri: any;
  occasionId: any = 0;
  pageTitle: string = "Popular Gifts";

  constructor(private dataService: DataService, private route: ActivatedRoute) {
    this.apiBaseUri = environment.apiUrl;

    this.route.queryParams.subscribe(params => {
      this.occasionId = params.occasionid;
    });

  }

  ngOnInit() {
    this.getPopularGiftsData();
  }

  getPopularGiftsData() {
    let url = 'golp-products';
    if (this.occasionId > 0) {
      url = `golp-products/occasion/${this.occasionId}`;
    }
    this.dataService.get(url)
      .subscribe((response: any) => {
        this.popularGiftsData = response;
        if (this.occasionId > 0 && this.popularGiftsData?.length > 0) {
          this.pageTitle = this.popularGiftsData[0]?.giftOccasion;
        }
      });
  }

}
