import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { VerificationRequired } from '@app/models';
import { environment } from 'environments/environment';
declare var jQuery: any;

@Component({
  selector: 'appc-gifty-offers',
  templateUrl: './gifty-offers.component.html',
  styleUrls: ['./gifty-offers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GiftyOffersComponent implements OnInit {

  @Input() golpOffers: any[];

  golpOffersData: any[];
  apiBaseUri: any;

  constructor(private router: Router,) {
    this.apiBaseUri = environment.apiUrl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['golpOffers']) {
      this.golpOffersData = this.golpOffers;
      console.log(this.golpOffersData);
    }
  }

  itemClicked(item: any) {
    let navigationEgolps: NavigationExtras = {
      state: {
        transId: 'offerCardDetails',
        workQueue: false,
        services: 1,
        code: item,
      },
      queryParams: {
        vr: VerificationRequired.No
      }
    };

    this.router.navigate([`offer-card/card-details/${item.providerId}/${item.offerTemplateId}`], navigationEgolps);
  }

  ngOnInit() {
    (function ($) {
      let baseColor = JSON.parse(localStorage.getItem('themeInfo'))?.base;
      $(document).ready(function () {
        $('.gift-list-slick').not('.slick-initialized').slick({
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 3,
          centerMode: true,
          //variableWidth: true,
          centerPadding: "0px",
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                variableWidth: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                variableWidth: true
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ],
          prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-right"></i></button>'
        });

        if (baseColor != undefined) {
          baseColor += ' !important'
        }
      });

    })(jQuery);
  }

}
