import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';

@Component({
  selector: 'appc-merchant-selection',
  templateUrl: './merchant-selection.component.html',
  styleUrls: ['./merchant-selection.component.scss']
})
export class MerchantSelectionComponent extends BaseComponent {

  @Output() selectedMerchantData = new EventEmitter<any>();
  
  skipBtnColor:string;
  circleColor:string;
  constructor(private router: Router) {
    super();
  }

  setDesign() {
    this.skipBtnColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.circleColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
  }

  ngOnInit() {
  }

  onSelectedMerchant($event: any) {
    this.selectedMerchantData.next($event);
  }

  onSkip() {
    this.router.navigate(['send-gift-information']);
  }

}
