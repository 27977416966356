import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/components/base/base.component';
import { DataService } from '@app/shared';
import { environment } from 'environments/environment';

@Component({
  selector: 'appc-received-gifts',
  templateUrl: './received-gifts.component.html',
  styleUrls: ['./received-gifts.component.scss']
})
export class ReceivedGiftsComponent extends BaseComponent {

  giftsData: any[];
  
  receivedData: any[];
  availed: any[];
  expired: any[];
  receivedCount: string;
  availedCount: string;
  expiredCount: string;
  apiBaseUri: any;

  //for design
  navTitleColor:string;

  constructor(private dataService: DataService) { 
    super();
    this.apiBaseUri = environment.apiUrl;
  }

  ngOnInit() {
    this.getGiftsData();
    this.getGiftsAvailedData();
    this.getGiftsExpiredData();
  }

  setDesign() {
    this.navTitleColor = 'color:' + this.getCssWithImportant(this.themeInfo ?.base);
  }

  getGiftsData() {
    let uri = `offerassignments/offer-filter-type/1/new-offer/${localStorage.getItem("consumerId")}`
    this.dataService.get(uri)
      .subscribe((response: any) => {
        this.receivedData = response?.data;
       
        if(this.receivedData != undefined && this.receivedData != null  && this.receivedData.length > 0){
      
          this.receivedCount = (this.receivedData.length > 9 ? '9' : this.receivedData.length) + ((this.receivedData.length > 9 )? '+':'');
        }
        else {this.receivedCount = '0';}
      });
  }

  getGiftsAvailedData() {
    let uri = `offerassignments/offer-filter-type/1/availed-offer/${localStorage.getItem("consumerId")}`
    this.dataService.get(uri)
      .subscribe((response: any) => {
        this.availed = response;

        if(this.availed != undefined && this.availed != null && this.availed.length > 0){
          this.availedCount = (this.availed.length > 9 ? '9' : this.availed.length) + ((this.availed.length > 9 )? '+':'');
        }
        else {this.availedCount = '0';}
      });
  }

  getGiftsExpiredData() {
    let uri = `offerassignments/offer-filter-type/1/expired-offer/${localStorage.getItem("consumerId")}`
    this.dataService.get(uri)
      .subscribe((response: any) => {
        this.expired = response;

        if(this.expired != undefined && this.expired != null && this.expired.length > 0){
          this.expiredCount = (this.expired.length > 9 ? '9' : this.expired.length) + ((this.expired.length > 9 )? '+':'');
        }
        else {this.expiredCount = '0';}
      });
  }

}
