import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'appc-merchant-category',
  templateUrl: './merchant-category.component.html',
  styleUrls: ['./merchant-category.component.scss']
})
export class MerchantCategoryComponent implements OnInit {
  @Input() categories: any[];

  categoriesData: any[];
  apiBaseUri: any;

  constructor(private router: Router) {
    this.apiBaseUri = environment.apiUrl;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['categories']) {
      this.categoriesData = this.categories;
    }
  }

  onClickCategory(item: any) {
    this.router.navigate([`merchants`], { queryParams: { categoryid: item.id } });
  }
}
