import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@app/shared';
import { environment } from 'environments/environment';

@Component({
  selector: 'appc-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss']
})
export class OfferListComponent implements OnInit {

  popularGiftsData: any[];
  apiBaseUri: any;
  pageTitle: string = "Popular Gifts";

  constructor(private dataService: DataService, private route: ActivatedRoute) {
    this.apiBaseUri = environment.apiUrl;

    this.route.queryParams.subscribe(params => {
    });

  }

  ngOnInit() {
    this.getMyOffers();
  }

  getMyOffers() {
    let url = 'golp-products';
  }

}
