import { Component, Input } from '@angular/core';

import { FieldBaseComponent } from '../field-base';

@Component({
  selector: 'app-form-input',
  styleUrls: ['form-input.component.scss'],
  templateUrl: 'form-input.component.html',
})
export class FormInputComponent extends FieldBaseComponent {
  // @Input() styleClass: any = 'form-control';
  // @Input() showIcon: boolean = false;
  // @Input() iconSource 

  
  getClass(cls: String){
    return cls + " " + this.config.cssClass;
  }
  getOnChangeControl(value){
    return (this.config?.onChange != undefined && this.config?.onChange != null) ? this.config?.onChange(value) : ()=>{};
  }

  getOnfocus(){
    console.log("focusCalled : " + this.config?.name + " : " + this.config?.focus);
    return  (this.config?.focus != undefined && this.config?.focus != null) ? this.config?.focus : false;
  }
  
}
