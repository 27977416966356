import { Component, OnInit } from '@angular/core';
import { FieldBaseComponent } from '../field-base';

@Component({
  selector: 'appc-form-custompriceselect',
  templateUrl: './form-custompriceselect.component.html',
  styleUrls: ['./form-custompriceselect.component.scss']
})
export class FormCustompriceselectComponent extends FieldBaseComponent {

  previousOption:any;
  alreadyExtended: boolean = false;
  currentIndex = 1;
  maxShowedIndex = 3;
  
  getClass(option, index){
    if(option.selected){
      this.previousOption = option;
      if(index >= this.maxShowedIndex)
        this.alreadyExtended = true;
      return "custom-price-selectedbutton";
    }
    return "custom-price-button";
  }

  onClick(option) {
    if(this.previousOption != null)
    {
      this.previousOption.selected = false;
      this.previousOption.img="assets/images/icon-currency-small.png"
    }
    option.selected = true;
    option.img="assets/images/icon_taka.png"
    this.previousOption = option;
  }

  seeMoreClicked() {
    this.alreadyExtended = true;
  }
}
