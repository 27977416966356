import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'message-modal-controller',
  templateUrl: './message-modal-viwe.html',
  styleUrls: ['./message-modal-style.scss']
})
export class MessageModalController implements OnInit {
  message: any;
  contact: any;
  constructor(
    private dialogRef: MatDialogRef<MessageModalController>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.message = data.message;
    this.contact = data.contact;
    console.log(data);
  }

  ngOnInit() {
  }
}
