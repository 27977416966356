import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
declare var jQuery: any;

@Component({
  selector: 'appc-my-gift',
  templateUrl: './my-gift.component.html',
  styleUrls: ['./my-gift.component.scss']
})
export class MyGiftComponent extends BaseComponent {

  @Input() myGifts: any[];

  myGiftsData: any[];
  apiBaseUri: any;
  viewAllBtnColor:string;
  constructor(private router: Router) {
    super();
    this.apiBaseUri = environment.apiUrl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['myGifts']) {
      this.myGiftsData = this.myGifts;
    }
  }

  ngOnInit() {
    (function ($) {
      let baseColor = JSON.parse(localStorage.getItem('themeInfo'))?.base;
      $(document).ready(function () {
        $('.gift-list-slick').not('.slick-initialized').slick({
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 3,
          centerMode: true,
          //variableWidth: true,
          centerPadding: "0px",
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                variableWidth: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                variableWidth: true
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ],
          prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-right"></i></button>'
        });

        if(baseColor != undefined) {
          baseColor += ' !important'
        }
      });
      
    })(jQuery);
  }

  viewAllGifts() {
    this.router.navigate(['/received-gifts']);
  }

  setDesign() {
    this.viewAllBtnColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
  }

}
