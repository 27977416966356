import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BaseComponent } from '../base/base.component';

declare var jQuery: any;

@Component({
  selector: 'appc-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent extends BaseComponent {
  @ViewChildren("brandNava") brandNavigation: QueryList<ElementRef>;
  images:any[] = [];

  //for design 
  titleColor:string;
  titleStyle:string;
  titleAvailableBrandStyle:string;
  constructor() { 
    super();
    this.populateImages();
  }

  populateImages() {
    this.images.push("Shwapno.png");
    this.images.push("Artisan.png");
    this.images.push("Smartex.jpg");
    this.images.push("Lubnan Richman.png");
    this.images.push("Raymond.png");
    this.images.push("Crocodile.jpg");
    this.images.push("Go Zayaan.png");
    this.images.push("Rokomari.png");
    this.images.push("Plus Point.jpg");
    this.images.push("Sarah.png");
    this.images.push("Orion.png");
    this.images.push("chillox.jpg");
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    configureSlick();
    this.brandNavigation.changes.subscribe(() => {
      configureSlick();
    });
  }

  setDesign() {
    this.titleColor = 'color:' + this.getCssWithImportant(this.themeInfo ?.base);
    this.titleStyle = 'text-align: left; padding: 15px 20px 0px 20px; justify-content:space-between' + '; color:' + this.getCssWithImportant(this.themeInfo ?.base);
    this.titleAvailableBrandStyle = 'text-align: center; margin-top: 30px;' + ' color:' + this.getCssWithImportant(this.themeInfo ?.base);
  }

}



function configureSlick() {
  (function ($) {
    $(document).ready(function () {
      if ($(window).width() > 992 && $('#brandNava') !== null) {
            $('#brandNava.slick-initialized').slick('unslick');
          }
      else if ($('#brandNava') !== null) {
      if ($('#brandNava') !== null) {
        $('#brandNava').not('.slick-initialized').slick({
          dots: false,
          infinite: false,
          speed: 300,
          centerMode: false,
          //variableWidth: true,
          centerPadding: "0",
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToScroll: 3,
                variableWidth: true
              }
            }
          ],
          prevArrow: '<button type="button" class="slick-prev square-shape"><i class="fas fa-arrow-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next square-shape"><i class="fas fa-arrow-right"></i></button>'
        });

      }}

     
    });
  })(jQuery);
}
