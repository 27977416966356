import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { PrebootModule } from 'preboot';

import { environment } from '../environments/environment';

import { routes } from './app.routes';
// Components
import { FooterComponent, HeaderComponent, PrivacyComponent } from '@app/components';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './shared';
import { TokenLoginComponent } from './components/token-login/token-login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { GiftyAppSectionComponent } from './components/gifty-app-section/gifty-app-section.component';
import { MerchantCategoryComponent } from './components/dashboard/merchant-category/merchant-category.component';
import { MerchantComponent } from './components/dashboard/merchant/merchant.component';
import { OccasionalGiftComponent } from './components/dashboard/occasional-gift/occasional-gift.component';
import { PopularGiftComponent } from './components/dashboard/popular-gift/popular-gift.component';
import { GiftyOffersComponent } from './components/dashboard/gifty-offers/gifty-offers.component';
import { MyGiftComponent } from './components/dashboard/my-gift/my-gift.component';
import { GiftsComponent } from './components/gifts/gifts.component';
import { GiftItemComponent } from './components/shared/gift-item/gift-item.component';
import { MerchantListComponent } from './components/merchant-list/merchant-list.component';
import { GiftDetailsComponent } from './components/gifts/gift-details/gift-details.component';
import { SendGiftsComponent } from './components/send-gifts/send-gifts.component';
import { MerchantSelectionComponent } from './components/send-gifts/merchant-selection/merchant-selection.component';
import { MerchantsGridComponent } from './components/shared/merchants-grid/merchants-grid.component';
import { GiftSendingProgressComponent } from './components/shared/gift-sending-progress/gift-sending-progress.component';
import { GiftInformationComponent } from './components/send-gifts/gift-information/gift-information.component';
import { GiftConfirmationComponent } from './components/send-gifts/gift-confirmation/gift-confirmation.component';
import { GiftPaymentComponent } from './components/send-gifts/gift-payment/gift-payment.component';
import { GiftDeliveredComponent } from './components/send-gifts/gift-delivered/gift-delivered.component';
import { PopularGiftListComponent } from './components/popular-gift-list/popular-gift-list.component';
import { PopularGiftItemComponent } from './components/shared/popular-gift-item/popular-gift-item.component';
import { GiftSentsComponent } from './components/gift-sents/gift-sents.component';
import { SentGiftDetailsComponent } from './components/gift-sents/sent-gift-details/sent-gift-details.component';
import { GiftMerchantSelectionComponent } from './components/gifts/gift-merchant-selection/gift-merchant-selection.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OfferListComponent } from './components/offer-list/offer-list.component';
import { ReceivedGiftsComponent } from './components/dashboard/received-gifts/received-gifts.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QrScannerComponent } from './components/shared/qr-scanner/qr-scanner.component';
import { BaseComponent } from './components/base/base.component';
import { MyPrivilegeCardComponent } from './components/my-card/my-privilege-card/my-privilege-card.component';
import { MyPrivilegeCardFacilitiesComponent } from './components/my-card/my-privilege-card-facilities/my-privilege-card-facilities.component';
import { OutletsComponent } from './components/outlets/outlets.component';
import { DashboardPrivilegeCardComponent } from './components/dashboard/dashboard-privilege-card/dashboard-privilege-card.component';
import { OfferCardDetailsComponent } from './components/offer-cards/offer-card-details/offer-card-details.component';
import { VerifyCustomerComponent } from './components/offer-cards/verify-customer/verify-customer.component';
import { MessageModalController } from './components/shared/message-modal/message-modal-controller';
import { MatDialogModule } from '@angular/material/dialog';
@NgModule({
  declarations: [
    // Components
    AppComponent,

    HomeComponent,
    FooterComponent,
    HeaderComponent,
    PrivacyComponent,
    TokenLoginComponent,
    DashboardComponent,
    GiftyAppSectionComponent,
    MerchantCategoryComponent,
    MerchantComponent,
    OccasionalGiftComponent,
    PopularGiftComponent,
    GiftyOffersComponent,
    MyGiftComponent,
    GiftsComponent,
    GiftItemComponent,
    MerchantListComponent,
    GiftDetailsComponent,
    SendGiftsComponent,
    MerchantSelectionComponent,
    MerchantsGridComponent,
    GiftSendingProgressComponent,
    GiftInformationComponent,
    GiftConfirmationComponent,
    GiftPaymentComponent,
    GiftDeliveredComponent,
    PopularGiftListComponent,
    PopularGiftItemComponent,
    GiftSentsComponent,
    SentGiftDetailsComponent,
    GiftMerchantSelectionComponent,
    ProfileComponent,
    OfferListComponent,
    ReceivedGiftsComponent,
    AboutUsComponent,
    QrScannerComponent,
    BaseComponent,
    MyPrivilegeCardComponent,
    MyPrivilegeCardFacilitiesComponent,
    OutletsComponent,
    DashboardPrivilegeCardComponent,
    OfferCardDetailsComponent,
    VerifyCustomerComponent,
    MessageModalController
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    // PrebootModule.withConfig({ appRoot: 'appc-root' }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    RouterModule.forRoot(routes, { initialNavigation: 'enabled' }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SharedModule,
    SlickCarouselModule,
    ZXingScannerModule,
    MatDialogModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
