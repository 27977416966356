import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { DataService } from '@app/shared';
import { MerchantDiscountResponse } from '@app/shared/models/view-models/merchant-discout-response';

import { AlertifyService } from '@app/shared/services/alertify.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'appc-send-gifts',
  templateUrl: './send-gifts.component.html',
  styleUrls: ['./send-gifts.component.scss']
})
export class SendGiftsComponent extends BaseComponent {

  // private fields

  merchantDetails: any;
  showTermsAndConditons: boolean = false;
  discountMerchants: any[] = [];
  navigationData: NavigationData;
  isBkash: boolean = false;
  skipBtnColor: string;
  continueBtnColor: string;
  constructor(private router: Router, private alertService: AlertifyService, private dataService: DataService) {
    super();
    // if(this.isBkash)
    //   this.getDiscountMerchants();
    this.navigationData = new NavigationData();
  }

  ngOnInit() {
  }

  onSelectMerchant($event: any) {
    this.merchantDetails = $event;
    console.log(this.merchantDetails);
  }

  onContinue() {
    if (this.merchantDetails == undefined || this.merchantDetails == null) {
      this.alertService.alert("", "Please select a merchant to continue or skip for now")
      return;
    }
    this.navigationData.merchantDetails = this.merchantDetails;
    let navigationExtras: NavigationExtras = {
      state: {
        transId: 'merchantSelected',
        workQueue: false,
        services: 1,
        code: this.navigationData
      }
    };

    this.router.navigate(['/send-gift-information'], navigationExtras);
  }

  onSkip() {
    let navigationExtras: NavigationExtras = {
      state: {
        transId: 'merchantSelected',
        workQueue: false,
        services: 1,
        code: this.navigationData
      }
    };

    this.router.navigate(['/send-gift-information'], navigationExtras);
  }

  setDesign() {
    this.continueBtnColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.skipBtnColor = 'border-color:' + this.getCssWithImportant(this.themeInfo?.base) + '; color:' + this.getCssWithImportant(this.themeInfo?.base);
  }

  getDiscountMerchants() {
    let resellerId = 'FFC7C23B-3401-409A-AD46-65C18AEB9CCE';
    let uri = `Discounts/merchant-discount-settings/${resellerId}`;

    this.dataService.get(uri).subscribe((res: any) => {
      if (res != undefined && res != null && res?.success) {
        this.showTermsAndConditons = true;
        if (res.data != undefined && res.data != null && res.data.length > 0)
          this.discountMerchants = res.data;
        this.navigationData.discountMerchant = res;
      }
      else {
        this.showTermsAndConditons = false;
      }
    });
  }

}

export class NavigationData {
  discountMerchant: any;
  merchantDetails: any;
}

