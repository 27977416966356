import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService, EventManagerService, Events, AuthorizeService } from '@app/shared';
import { GetHtmlElementResponse } from '@app/shared/models/view-models/get-html-element';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../base/base.component';

declare var jQuery: any;

@Component({
  selector: 'appc-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent extends BaseComponent {
  loggedIn: boolean = false;
  appBackButton: any;
  showAppLink: boolean = false;

  /// for theme
  infoLinkColor: string;
  webLinkColor: string;

  resellerId = "2";
  color: string;
  backgroundColor: string;
  borderColor: string;
  agent: string = 'golp';


  //event subscription
  loginSubscription: Subscription;
  constructor(private eventManager: EventManagerService, private authorizeService: AuthorizeService, private dataService: DataService, private sanitized: DomSanitizer) {
    super();
    this.loginSubscription = this.eventManager.subscribe(Events.loginSuccessful, () => {
      this.loggedIn = true;
    });
    
  }

  ngOnInit(): void {
    this.agent = environment.agent;
    if (this.authorizeService.getUser()) {
      // this.loadConditionalUI();
    }
  }

  setDesign() {
    this.webLinkColor = this.infoLinkColor = 'color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.color = 'color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.backgroundColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.borderColor = 'border-color:' + this.getCssWithImportant(this.themeInfo?.base);
  }

  getBackButtonStyle() {
    return `${this.borderColor}; ${this.backgroundColor}; position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 8%;
    font-size: 18px;
    border-radius: 0px;
    margin-top: 2%;
    color: white !important;
    width: 100%;
    height: 50px;
    text-align: left;
    z-index: 9999;`;
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // loadBackButton(this.appBackButton);
  }

  loadConditionalUI() {
    try {
      this.dataService
        .get(`UIConfiguration/gethtmlelement/resellerid/${this.resellerId}/component/Footer?section=FooterBackButton&section=FooterAppIcon`)
        .subscribe(
          (response: GetHtmlElementResponse) => {
            var parseResponse = response?.data;
            if (parseResponse && parseResponse.result) {
              var footerBackButtonData = parseResponse?.data.find((element) => {
                return element.section == 'FooterBackButton';
              });

              var footerAppIconData = parseResponse.data?.find((element) => {
                return element.section == 'FooterAppIcon';
              });

              if (footerAppIconData?.htmlElement == 'icon') this.showAppLink = true;
              if (footerBackButtonData) {

                // var parser = new DOMParser();
                // var doc = parser.parseFromString(footerBackButtonData.htmlElement, 'text/html');

                // //var safeHtml = this.sanitized.bypassSecurityTrustScript(footerBackButtonData.htmlElement);
                // this.appBackButton = this.sanitized.bypassSecurityTrustHtml(doc.body.innerHTML);
                console.log("response : ", footerBackButtonData.htmlElement)
                this.appBackButton = JSON.parse(footerBackButtonData.htmlElement);
                console.log("backbutton result : ", this.appBackButton);
                console.log("backbutton result : ", this.appBackButton.ui);
                //footerBackButtonData.htmlElement;
                // loadBackButton(this.appBackButton);
              }
            }
          },
          (error) => console.log(error),
        );

    } catch (e) {
      // statements to handle any exceptions
      console.log(e); // pass exception object to error handler
    }
  }
}

function loadBackButton(backButton: string) {
  (function ($) {
    var input = backButton.replace(/"/g, '\'');
    $(document).ready(function () {
      $('#appBackButton').innerHTML += `${input}`;

    })
    $('#appBackButton').innerHTML += `${input}`;
  })(jQuery);
}
