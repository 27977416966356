import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'appc-occasional-gift',
  templateUrl: './occasional-gift.component.html',
  styleUrls: ['./occasional-gift.component.scss']
})
export class OccasionalGiftComponent implements OnInit {

  @Input() occasions: any[];

  occasionsData: any[];
  apiBaseUri: any;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.apiBaseUri = environment.apiUrl;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['occasions']) {
      this.occasionsData = this.occasions;
    }
  }

  onClickOccasion(item: any) {
    this.router.navigate(['popular-gifts'], { queryParams: { occasionid: item.id } })
  }

}
