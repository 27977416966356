import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { User } from 'oidc-client';

import { AppService, AuthorizeService, DataService, EventManagerService, Events } from '@app/shared';

import { routes } from '../../+examples/examples.routes';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { Subscription } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'appc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent {
  isCollapsed = false;
  userName: any;
  userPhoneNumber: any;
  userNameSmall: any;
  profileImage: String;
  exampleRoutes = [...routes];

  @ViewChild("content") content: ElementRef;
  //for design
  sendBtnColor:string;
  
  //event subscription
  loginSubscription: Subscription;
  constructor(private authService: AuthorizeService, private appService: AppService,
    private router: Router, private eventManager: EventManagerService, private dataService: DataService, private modalService: NgbModal) {

    super();
    this.userName = localStorage.getItem('name');
    this.userPhoneNumber = localStorage.getItem('phoneNumber');
    
    var basicProfile = JSON.parse(localStorage.getItem('profile'));
      if(basicProfile != undefined && basicProfile != null){
        this.profileImage = basicProfile.avatar;
    }
    

    if(this.authService.isLoggedIn){
      this.loadProfile();
      if(this.loginSubscription){
        this.eventManager.destroy(this.loginSubscription);
      }
    }
    else{
      this.loginSubscription = this.eventManager.subscribe(Events.loginSuccessful, () => {
        this.loadProfile();
        this.eventManager.destroy(this.loginSubscription);
      });
    }
  }

  loadProfile(){
    this.userName = localStorage.getItem('name');
      this.userPhoneNumber = localStorage.getItem('phoneNumber');
      if(this.userName && this.userName !=="")  {this.userNameSmall = this.userName;}
      else {this.userNameSmall = this.userPhoneNumber;}
      var basicProfile = JSON.parse(localStorage.getItem('profile'));
      if(basicProfile != undefined && basicProfile != null){
        this.profileImage = basicProfile.avatar;
      }
  }

  open(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {},
			(reason) => {},
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  get isLoggedIn(): boolean {
    return localStorage.getItem('token') != null && localStorage.getItem('consumerId') != null;
  }
  get user(): User {
    return this.authService.user;
  }
  get cultures(): ICulture[] {
    //return this.appService.appData.cultures;
    let c1: ICulture = {
      value: "en-US",
      text: "English",
      current: true
    };
    return [c1];
  }
  get currentCulture(): ICulture {
    return this.cultures.filter(x => x.current)[0];
  }

  setDesign() {
    this.sendBtnColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
  }

  ngOnInit(): void {
    this.appService.profileImageUpdate$.subscribe((profileImage) => this.profileImage = profileImage);
    this.appService.profileNameUpdate$.subscribe((profileName) => {
      this.userName = profileName;
      });
      this.userName = localStorage.getItem('name');
      this.userPhoneNumber = localStorage.getItem('phoneNumber');
      if(this.userName && this.userName !=="")  {this.userNameSmall = this.userName;}
      else {this.userNameSmall = this.userPhoneNumber;}
    // this.userNameSmall = (this.userName != undefined && this.userName.length) > 20 ? this.userName.split(" ", 1)[0] : this.userName
  }

  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
  }

  onLogout() {
    this.dataService.post(`auth/logout`, null)
    .subscribe((response: any) => {
      this.removeDataAndNavigate();
    },(error)=>{this.removeDataAndNavigate();});
  }


  removeDataAndNavigate(){
    this.loginSubscription = this.eventManager.subscribe(Events.loginSuccessful, () => {
      this.loadProfile();
      this.eventManager.destroy(this.loginSubscription);
    });
    this.authService.logout();
    this.router.navigate(['/authentication/login']);
  }
}
