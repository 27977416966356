import { Component, OnInit } from '@angular/core';
import { DataService, EventManagerService, Events } from '@app/shared';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'appc-token-login',
  templateUrl: './token-login.component.html',
  styleUrls: ['./token-login.component.scss']
})
export class TokenLoginComponent implements OnInit {

  loggedInUserInfo: any;
  token: any;
  isVerificationFailed: any = false;

  constructor(private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private eventManager: EventManagerService) {
    this.token = this.route.snapshot.params.token;
  }

  ngOnInit() {
    this.loginByToken();
  }

  // this will be changle later
  loginByToken() {
    this.dataService.get(`ServiceConsumers/in-app-token-verification/${this.token}`)
      .subscribe((response: any) => {
        if (response.status) {
          this.loggedInUserInfo = response.data;


          localStorage.setItem("token", response.token);
          localStorage.setItem("consumerId", this.loggedInUserInfo.consumerId);
          localStorage.setItem("name", `${this.loggedInUserInfo.name}`);
          localStorage.setItem("phoneNumber", this.loggedInUserInfo.phoneNumber);

          this.eventManager.broadcast({ name: Events.loginSuccessful });
          this.router.navigate(['/dashboard']);
        }
        if (!response.status) {
          this.isVerificationFailed = true;
          localStorage.removeItem("token");
          localStorage.removeItem("consumerId");
          localStorage.removeItem("phoneNumber");
        }
      });
  }

}
