import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { DataService } from '@app/shared';

@Component({
  selector: 'appc-gift-delivered',
  templateUrl: './gift-delivered.component.html',
  styleUrls: ['./gift-delivered.component.scss']
})
export class GiftDeliveredComponent extends BaseComponent {

  giftInformation: any;
  isDelivered: boolean;
  orderId: any;
  deliveredGift: any;

  btnBackText = "BACK TO HOME";
  btnMoreText = "SEND MORE GIFTS";
  pageHeaderText = "Thank you for sending gift";

  //for design
  skipBtnColor: string;
  continueBtnColor: string;

  constructor(private router: Router,
    private dataService: DataService,
    private route: ActivatedRoute) {
    super();
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state != undefined) {
      this.giftInformation = state.code;
    }
  }

  ngOnInit() {
    let deliveredStatus = this.route.snapshot.params.deliveryStatus;
    this.orderId = this.route.snapshot.params.orderId;

    this.isDelivered = deliveredStatus === 'true' ? true : false;

    if (this.isDelivered) {
      this.getSendGiftInformation();
    }
    if (!this.isDelivered) {
      this.btnBackText = "BACK TO HOME";
      this.btnMoreText = "TRY AGAIN";
      this.pageHeaderText = "Payment Unsuccessful";
    }
  }

  onBack() {
    this.router.navigate(['/']);
  }

  setDesign() {
    this.continueBtnColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.skipBtnColor = 'border-color:' + this.getCssWithImportant(this.themeInfo?.base) + '; color:' + this.getCssWithImportant(this.themeInfo?.base);
  }

  onClickMoreGifts() {
    this.router.navigate(['/send-gift']);
  }

  getSendGiftInformation() {
    let url = `GiftCardOrders/consumer-order-summary/${localStorage.getItem("consumerId")}/order/${this.orderId}`;
    this.dataService.get(url).subscribe((response: any) => {
      this.deliveredGift = response;
      console.log(this.deliveredGift);
    });
  }

}
