import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsService, AppFormComponent, IData, DataService } from '@app/shared';
import { IFieldConfig, FieldTypes } from '@app/models';
import { Router, NavigationExtras } from '@angular/router';
import { environment } from 'environments/environment';
import { stat } from 'fs';
import { MerchantDiscountResponse } from '@app/shared/models/view-models/merchant-discout-response';
import { MerchantOutletResponse } from '@app/shared/models/view-models/merchant-outlets-response';
import { BaseComponent } from '@app/components/base/base.component';


declare var jQuery: any;
@Component({
  selector: 'appc-gift-information',
  templateUrl: './gift-information.component.html',
  styleUrls: ['./gift-information.component.scss']
})
export class GiftInformationComponent extends BaseComponent {

  @ViewChild(AppFormComponent, { static: true }) form: AppFormComponent;
  config: IFieldConfig[];

  apiBaseUri: any;
  giftInfoForm: FormGroup;
  submitted = false;
  useSlab = false;
  isPopularGift: boolean = false;
  isResendGift: boolean = false;
  popularGiftMerchant: any;
  popularGiftMerchantLogo: any;
  popularGiftPrice: any;
  slabs: any;
  giftInformation: any = {};
  hasGiftAmountError: boolean = false;
  currentTransId: String = "";
  finalpayableAmount: any = 0;
  hasDiscount: boolean = false;
  giftPercentage: number = 0;
  maxGiftAmount: number = 0;
  currentMerchantId: number = 0;
  giftProvider: any;
  observer: any;
  popularGiftAmount: number = 0;
  resendGiftAmount: number = 0;
  isMerchantOutletLoaded: boolean = false;
  mercantOutlets: MerchantOutletResponse[] = [];
  merchantDiscountResponse: MerchantDiscountResponse;
  currentSlabsWithAction: any;
  giftAssignmentId: any;

  //for design
  skipBtnColor: string;
  continueBtnColor: string;
  termsAndInfoHeader: string;
  termsAndInfoLink: string;
  circleColor: string;
  color: string;
  backGroundColor: string;
  constructor(private formBuilder: FormBuilder,
    private router: Router, private dataService: DataService, private elRef: ElementRef) {

    super();
    this.apiBaseUri = environment.apiUrl;

    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state !== undefined && state.code !== undefined) {
      if (state.transId != undefined && state.transId != null && state.transId == "giftInformation") {
        this.giftInformation = state.code;
        this.merchantDiscountResponse = this.giftInformation.merchantDiscountResponse;
        this.hasDiscount = this.giftInformation?.hasDiscount;
        this.giftPercentage = this.giftInformation?.discountPercentage;
        this.isPopularGift = this.giftInformation?.isPopularGift !== undefined && this.giftInformation?.isPopularGift !== null ? this.giftInformation?.isPopularGift : false;
        this.isResendGift = this.giftInformation?.isResendGift !== undefined && this.giftInformation?.isResendGift !== null ? this.giftInformation?.isResendGift : false;
        this.maxGiftAmount = this.giftInformation?.highestDiscountAmount;
        this.giftAssignmentId = this.giftInformation?.giftAssignmentId;
        if (this.isPopularGift) this.popularGiftAmount = state.code.popularGiftAmount;
        if (this.isResendGift) this.resendGiftAmount = state.code.resendGiftAmount;
      }
      else if (state.transId != undefined && state.transId != null && state.transId == "merchantSelected") {
        this.giftInformation = state.code.merchantDetails;
        this.hasDiscount = this.giftInformation?.hasDiscount;
        this.merchantDiscountResponse = state.code.discountMerchant;
      }
      else {
        this.giftInformation = state.code;
        this.hasDiscount = this.giftInformation?.hasDiscount;
        this.isPopularGift = this.giftInformation?.isPopularGift !== undefined && this.giftInformation?.isPopularGift !== null ? this.giftInformation?.isPopularGift : false;
        this.isResendGift = this.giftInformation?.isResendGift !== undefined && this.giftInformation?.isResendGift !== null ? this.giftInformation?.isResendGift : false;
        this.giftAssignmentId = this.giftInformation?.giftAssignmentId;
        if (this.isPopularGift) this.popularGiftAmount = state.code.giftAmount;
        if (this.isResendGift) this.resendGiftAmount = state.code.giftAmount;
      }
    }

    if (state != undefined && state.transId != undefined && state.transId != null) {
      this.currentTransId = state.transId;
      if (state.transId == "giftInformation") {
        this.useSlab = this.giftInformation?.useSlabs;
        if (this.useSlab) {
          this.slabs = this.giftInformation?.slabs;
        }
      }
      else {
        var giftAmount = this.giftInformation?.companySettings?.GiftAmount;
        if (giftAmount != undefined && giftAmount != null) {
          this.useSlab = JSON.parse(this.giftInformation?.companySettings?.GiftAmount).useSlab;
          if (this.useSlab)
            this.slabs = JSON.parse(this.giftInformation?.companySettings?.GiftAmount).slabs;
        }
      }
    }
    this.getSelectedMerchantOutlets();
  }

  ngOnInit() {
    this.buildForm();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function (mutation) {
        console.log(mutation.type);
      });
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }

  setDesign() {
    this.continueBtnColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.skipBtnColor = 'border-color:' + this.getCssWithImportant(this.themeInfo?.base) + '; color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.termsAndInfoHeader = 'background:' + this.getCssWithImportant(this.themeInfo?.base);
    this.termsAndInfoLink = 'color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.color = 'color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.circleColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.backGroundColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
  }

  getSelectedMerchantOutlets() {

    if (this.currentTransId && this.currentTransId == "giftInformation") {
      this.mercantOutlets = this.giftInformation.mercantOutlets;
    }
    else {
      if (this.giftInformation?.id != undefined && this.giftInformation?.id != null) {
        let url = `Outlets/company/${this.giftInformation?.id}`;
        this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
          if (data != null && data.length > 0) {
            this.mercantOutlets = data;
          }
        });
      }
    }
  }

  getDiscountMerchants() {
    if (!this.isPopularGift && !this.isResendGift && this.merchantDiscountResponse != null) {
      if (this.merchantDiscountResponse.success && this.merchantDiscountResponse.data != undefined && this.merchantDiscountResponse.data != null && this.merchantDiscountResponse.data.length > 0) {
        if (this.currentTransId && this.currentTransId == "giftInformation") {
          if (this.giftInformation?.merchantId != undefined && this.giftInformation?.merchantId != null && this.giftInformation?.merchantId > 0)
            this.giftProvider = this.merchantDiscountResponse.data.find(element => element.merchantId == this.giftInformation.merchantId);
          else this.giftProvider = this.merchantDiscountResponse.data.find(element => element.merchantId == 0);

          if (this.giftProvider) {
            this.hasDiscount = true;
            this.giftPercentage = this.giftProvider.discountPercent;
            this.maxGiftAmount = this.giftProvider.maxDiscount;
            this.buildForm();
          }
        }
        else {
          if (this.giftInformation?.id != undefined && this.giftInformation?.id != null && this.giftInformation?.id > 0)
            this.giftProvider = this.merchantDiscountResponse.data.find(element => element.merchantId == this.giftInformation.id);
          else this.giftProvider = this.merchantDiscountResponse.data.find(element => element.merchantId == 0);


          if (this.giftProvider) {
            this.hasDiscount = true;
            this.giftPercentage = this.giftProvider.discountPercent;
            this.maxGiftAmount = this.giftProvider.maxDiscount;
            this.buildForm();
          }
        }
      }
      else {
        this.hasDiscount = this.hasDiscount || false;
      }
    }
    else {
      let resellerId = 'FFC7C23B-3401-409A-AD46-65C18AEB9CCE';
      let uri = `Discounts/merchant-discount-settings/${resellerId}`;

      this.dataService.get(uri).subscribe((res: any) => {
        if (res != undefined && res != null && res?.success) {

          if (res.data != undefined && res.data != null && res.data.length > 0)
            this.merchantDiscountResponse = res;

          if (this.giftInformation?.merchantId != undefined && this.giftInformation?.merchantId != null && this.giftInformation?.merchantId > 0)
            this.giftProvider = this.merchantDiscountResponse.data.find(element => element.merchantId == this.giftInformation.merchantId);
          else this.giftProvider = this.merchantDiscountResponse.data.find(element => element.merchantId == 0);

          if (this.giftProvider) {
            this.hasDiscount = true;
            this.giftPercentage = this.giftProvider.discountPercent;
            this.maxGiftAmount = this.giftProvider.maxDiscount;
            this.buildForm();
          }
          else {
            this.hasDiscount = this.hasDiscount || false;;
          }
        }
        else {
          this.hasDiscount = this.hasDiscount || false;;
        }
      });
    }

  }


  //|| this.currentTransId != "giftInformation" || (this.giftInformation?.giftAmount !== undefined && this.giftInformation?.giftAmount != null && this.giftInformation?.giftAmount > 0)
  getPriceOption() {
    var priceOpton1 = {
      name: 'giftAmount',
      label: 'Enter Gift Amount',
      type: FieldTypes.Number,
      validation: [Validators.required],
      placeholder: 'xxxxx',
      showIcon: true,
      srcIcon: 'assets/images/icon-currency-small.png',
      value: this.giftInformation?.giftAmount !== undefined ? this.giftInformation?.giftAmount : '',
      disabled: this.isPopularGift || this.isResendGift,
    };

    var priceOption2 = {
      name: 'giftAmount',
      label: 'Select Gift Amount',
      type: FieldTypes.CustomPriceSelect,
      validation: [Validators.nullValidator],
      showIcon: true,
      srcIcon: 'assets/images/icon-currency-small.png',
      customOptions: this.populateFixedPrice(),

    }

    return this.useSlab && !this.isPopularGift && !this.isResendGift ? priceOption2 : priceOpton1;
  }

  private populateFixedPrice() {

    var data: IData[] = [];
    if (this.slabs == null || this.slabs == undefined)
      return [];
    this.slabs.forEach(element => {
      var pushData = { key: element, value: element, selected: false, id: element, img: "assets/images/icon-currency-small.png" };
      data.push(pushData)
    });
    this.currentSlabsWithAction = data;
    return data;
  }

  getPayableAmount(payableAmountData) {
    var data = {
      name: 'payableAmount',
      label: 'Payable Amount',
      type: FieldTypes.Number,
      validation: [Validators.required],
      placeholder: 'xxxxx',
      showIcon: true,
      srcIcon: 'assets/images/icon-currency-small.png',
      value: payableAmountData,
      disabled: true,
      cssIcon: "bkash-bird"
    };
    return data;
  }

  buildForm() {
    this.config = [
      {
        name: 'giftReceiver',
        label: "Enter Recipient's Mobile No.",
        type: FieldTypes.Textbox,
        validation: [Validators.required],
        placeholder: '01xxxxxxxxx',
        showIcon: true,
        srcIcon: 'assets/images/icon-person.png',
        value: this.giftInformation?.giftReceiver !== undefined ? this.giftInformation?.giftReceiver : ''
      },
      this.getPriceOption(),
      {
        name: 'message',
        label: 'Leave Your Message (optional)',
        type: FieldTypes.Textarea,
        placeholder: "Example, Happy birthday to you",
        value: this.giftInformation?.message !== undefined ? this.giftInformation?.message : ''
      },
      // {
      //   name: 'button',
      //   label: 'BACK',
      //   type: FieldTypes.Button,
      //   cssClass: 'btn-red-cornered form-control lg-red btn-half-block btn-flat-putlined hiddem-above-md  btn-normal btn-back',
      //   onSubmit: this.onBack.bind(this)
      // },
      // {
      //   name: 'button',
      //   label: 'CONTINUE',
      //   type: FieldTypes.Button,
      //   cssClass: 'btn-red-cornered form-control lg-red btn-half-block btn-continue',
      //   onSubmit: this.onSubmit.bind(this)
      // }
    ];
    // if (this.hasDiscount && (this.isPopularGift || !this.isResendGift)) {
    //   this.config.splice(2, 0, this.getPayableAmount(0));
    //   if (this.useSlab)
    //     configurePayment(this.giftPercentage, this.maxGiftAmount, this.setPayableAmount, this, this.config[1].customOptions);
    //   else configurePayment(this.giftPercentage, this.maxGiftAmount, this.setPayableAmount, this);
    // }
  }

  showMerchant() {
    return (this.giftInformation && this.giftInformation.companyLogoUrl) || (this.currentTransId == "giftInformation" && this.giftInformation.merchantLogo);
  }

  getCompanyLogo() {
    if (this.currentTransId == "giftInformation") return this.giftInformation.merchantLogo;
    else return this.giftInformation.companyLogoUrl;
  }

  getMerchantName() {
    if (this.currentTransId == "giftInformation") return this.giftInformation.merchant;
    return this.giftInformation.name;
  }

  setPayableAmount(data: number, component) {
    component.finalpayableAmount = data;
  }

  showMerchantAmount() {
    if (this.currentTransId == "giftInformation") return this.isPopularGift || false;
    return this.giftInformation.giftAmount && this.giftInformation.giftAmount > 0;
  }

  // convenience getter for easy access to form fields
  get f() { return this.giftInfoForm.controls; }

  getGiftInfo(data) {
    let giftAmount = 0.0;
    if (this.isResendGift)
      giftAmount = this.resendGiftAmount;
    else if (this.isPopularGift)
      giftAmount = this.popularGiftAmount;
    else giftAmount = data.giftAmount;
    if (this.currentTransId == "giftInformation") {

      return {
        "merchantId": this.giftInformation?.merchantId != undefined ? this.giftInformation?.merchantId : null,
        "merchant": this.giftInformation?.merchant != undefined ? this.giftInformation?.merchant : null,
        "merchantLogo": this.giftInformation?.merchantLogo != undefined ? this.giftInformation?.merchantLogo : null,
        "giftReceiver": data.giftReceiver,
        "giftAmount": giftAmount,
        "message": data.message,
        "slabs": this.slabs,
        "useSlabs": this.useSlab,
        "hasDiscount": this.hasDiscount ? this.hasDiscount : this.hasDiscount && (this.isPopularGift || !this.isResendGift),
        "discountAmount": this.finalpayableAmount,
        "discountPercentage": this.giftPercentage,
        "highestDiscountAmount": this.maxGiftAmount,
        "merchantDiscountResponse": this.merchantDiscountResponse,
        "mercantOutlets": this.mercantOutlets,
        "isPopularGift": this.isPopularGift,
        "isResendGift": this.isResendGift,
        "resendGiftAmount": this.resendGiftAmount,
        "popularGiftAmount": this.popularGiftAmount,
        "giftAssignmentId": this.giftAssignmentId
      }
    }
    return {
      "merchantId": this.giftInformation?.id != undefined ? this.giftInformation?.id : null,
      "merchant": this.giftInformation?.name != undefined ? this.giftInformation?.name : null,
      "merchantLogo": this.giftInformation?.companyLogoUrl != undefined ? this.giftInformation?.companyLogoUrl : null,
      "giftReceiver": data.giftReceiver,
      "giftAmount": giftAmount,
      "message": data.message,
      "slabs": this.slabs,
      "useSlabs": this.useSlab,
      "hasDiscount": this.hasDiscount ? this.hasDiscount : this.hasDiscount && (this.isPopularGift || !this.isResendGift),
      "discountAmount": this.finalpayableAmount,
      "discountPercentage": this.giftPercentage,
      "highestDiscountAmount": this.maxGiftAmount,
      "merchantDiscountResponse": this.merchantDiscountResponse,
      "mercantOutlets": this.mercantOutlets,
      "isPopularGift": this.isPopularGift,
      "isResendGift": this.isResendGift,
      "resendGiftAmount": this.resendGiftAmount,
      "popularGiftAmount": this.popularGiftAmount,
      "giftAssignmentId": this.giftAssignmentId
    }
  }

  onSubmit() {
    var isDirty = false;
    if (this.useSlab) {
      var giftAmount = this.getGiftAmount();
      this.form.form.value.giftAmount = giftAmount == undefined ? null : giftAmount;
      try {
        if (this.form.form.value.giftAmount == undefined)
          this.form.form.value.giftAmount = null;
      }
      catch (e) {
        console.log(e);
      }
    }

    if (this.form.form.status === "INVALID") {
      isDirty = true;
      this.form.form.markAllAsTouched();
    }

    if (this.useSlab && (this.form.form.value.giftAmount == undefined || this.form.form.value.giftAmount == null)) {
      this.hasGiftAmountError = true;
      isDirty = true;
    } else { this.hasGiftAmountError = false; }

    if (isDirty) return;

    let data = this.form.value;

    let giftInfo = this.getGiftInfo(data);

    let navigationExtras: NavigationExtras = {
      state: {
        transd: 'giftInformation',
        workQueue: false,
        services: 1,
        code: giftInfo,
      }
    };

    if (this.isResendGift) {
      let resendPayload = {
        "GiftId": this.giftInformation.id,
        "GiftAssignmentId": this.giftAssignmentId,
        "NewPhoneNumber": giftInfo.giftReceiver,
        "OldPhoneNumber": giftInfo.giftReceiver
      };

      this.dataService.post("ResendRequest", resendPayload)
        .subscribe((response: any) => {
        });
    }
    else this.router.navigate(['send-gift-confirmation'], navigationExtras);
  }

  getGiftAmount() {
    var data = this.config[1].customOptions.find(m => m.selected);
    return data == undefined ? null : data.value;
  }

  onBack() {
    this.router.navigate(['send-gift']);
  }

}

function configurePayment(percentageInput, maxGiftAmountInput, callback, component, slabsInput = null) {
  const percentage = percentageInput;
  const maxGiftAmount = maxGiftAmountInput;
  const slabs = slabsInput;
  (function ($) {
    $(document).ready(function () {
      setDesign();
      var actualAmount = Number($("#giftAmount").val());
      if (actualAmount > 0) {
        var discountAmount = Math.floor(actualAmount * (percentage / 100));
        if (discountAmount > maxGiftAmount)
          discountAmount = maxGiftAmount;
        var finalAmount = actualAmount - discountAmount;
        $("#payableAmount").val(finalAmount);
        callback(finalAmount, component);
      }
      $(document).on('keyup', '#giftAmount', function () {
        var actualAmount = Number($("#giftAmount").val());
        var discountAmount = Math.floor(actualAmount * (percentage / 100));
        if (discountAmount > maxGiftAmount)
          discountAmount = maxGiftAmount;
        var finalAmount = actualAmount - discountAmount;
        $("#payableAmount").val(finalAmount);
        callback(finalAmount, component);
      });

      $(".custom-price-button").click(function () {
        var latestSlabs = component.currentSlabsWithAction;
        var selectedSlub = latestSlabs.find(element => {
          return element.selected == true;
        });//Number($("#giftAmount").val());

        if (selectedSlub == null || selectedSlub == undefined) return;

        var actualAmount = Number(selectedSlub.value);
        var discountAmount = Math.floor(actualAmount * (percentage / 100));

        if (discountAmount > maxGiftAmount)
          discountAmount = maxGiftAmount;
        var finalAmount = actualAmount - discountAmount;
        $("#payableAmount").val(finalAmount);
        callback(finalAmount, component);
      });

      $("#seeMore").click(function () {
        $(".custom-price-button").click(function () {
          var latestSlabs = component.currentSlabsWithAction;
          var selectedSlub = latestSlabs.find(element => {
            return element.selected == true;
          });//Number($("#giftAmount").val());

          var actualAmount = Number(selectedSlub.value);
          var discountAmount = Math.floor(actualAmount * (percentage / 100));

          if (discountAmount > maxGiftAmount)
            discountAmount = maxGiftAmount;
          var finalAmount = actualAmount - discountAmount;
          $("#payableAmount").val(finalAmount);
          callback(finalAmount, component);
        });
      });
    });

    function setDesign() {

      let baseColor = JSON.parse(localStorage.getItem('themeInfo'))?.base;
      if (baseColor != undefined) {
        $(".form-control:valid").css("border-color", baseColor);
        $("input").keyup(function () {
          $(".is-valid").css("border-color", baseColor);
          $(".form-control:valid").css("border-color", baseColor);
        });
        $("input").change(function () {
          $(".is-valid").css("border-color", baseColor);
          $(".form-control:valid").css("border-color", baseColor);
        });
        $("input").click(function () {
          $(".is-valid").css("border-color", baseColor);
          $(".form-control:valid").css("border-color", baseColor);
        });
      }
    }
  })(jQuery);
}


