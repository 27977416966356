import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { IFieldConfig, FieldTypes, VerificationRequired } from '@app/models';
import { AppFormComponent, DataService } from '@app/shared';
import { AlertifyService } from '@app/shared/services/alertify.service';

@Component({
  selector: 'appc-verify-customer',
  templateUrl: './verify-customer.component.html',
  styleUrls: ['./verify-customer.component.scss']
})
export class VerifyCustomerComponent extends BaseComponent implements OnInit {

  @ViewChild(AppFormComponent, { static: true }) form: AppFormComponent;
  config: IFieldConfig[];

  providerId: any;
  offerCard: any;
  offerTemplateId: any = null;
  continueBtnColor:any;

  constructor(private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertifyService) {
    super();
    this.providerId = this.route.snapshot.queryParams['providerId'];
    this.offerTemplateId = this.route.snapshot.queryParams['offerTemplateId'];
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.config = [
      {
        name: 'customerIdentifier',
        //label: "Enter Recipient's Mobile No.",
        type: FieldTypes.Textbox,
        validation: [Validators.required],
        placeholder: 'Enter last 6 digit of you VISA card',
        showIcon: false,
        value: ''
      }
    ];
  }

  onSubmit() {
    let isDirty = false;

    if (this.form.form.status === "INVALID") {
      isDirty = true;
      this.form.form.markAllAsTouched();
    }
    if (isDirty) return;

    let data = this.form.value;

    let codeReceiverPayload = {
      'providerId': this.providerId,
      'customerIdentifier': data.customerIdentifier,
    };

    let url = `OfferProvider/verify-customer-identification`;
    this.dataService.post(url, codeReceiverPayload).subscribe((response: any) => {
      if (response.success) {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            vr: VerificationRequired.No
          }
        };

        this.router.navigate([`offer-card/card-details/${this.providerId}/${this.offerTemplateId}`], navigationExtras);

      }
      else {
        this.alertService.error(response.data);
      }
    }, err => console.log(err));
  }
}
