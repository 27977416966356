import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'appc-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss']
})
export class QrScannerComponent implements OnInit {

  qrResult: any;
  backUrl: any;
  navigationData: any;
  constructor( private router: Router,) { 
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };
    if (state !== undefined && state.code !== undefined) {
      this.navigationData = state.code;
      if(this.navigationData!= undefined && this.navigationData != null  && this.navigationData.url != undefined && this.navigationData.url != null)
        this.backUrl = this.navigationData.url;
    }
  }

  ngOnInit() {
  }

  onCodeResult(result){
    this.qrResult = result;
    var navigationData  = new QRCodeData();
    navigationData.qrCode = this.qrResult;
    let navigationExtras: NavigationExtras = {
      state: {
        transd: 'qrCode',
        workQueue: false,
        services: 1,
        code: navigationData,
      }
    };
    if(this.backUrl != null)
      this.router.navigate([this.backUrl], navigationExtras);
  }

  goBack(){
    if(this.backUrl != null)
      this.router.navigate([this.backUrl]);
  }
}

export class QRCodeData{
  qrCode:string;
}
