import { Component, Inject, OnInit, SecurityContext, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { DataService } from '@app/shared';
declare var jQuery: any;

@Component({
  selector: 'appc-dashboard-privilege-card',
  templateUrl: './dashboard-privilege-card.component.html',
  styleUrls: ['./dashboard-privilege-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardPrivilegeCardComponent extends BaseComponent implements OnInit {

  item: any;
  apiBaseUri: any;
  receivedData: any;
  viewAllBtnColor: string;

  constructor(@Inject('API_BASE_URL') private BASE_URL: string,
    private router: Router,
    private dataService: DataService) {
    super();
    this.loadCards();
  }


  getUserPhone() {
    let phoneNo = localStorage.getItem("phoneNumber");
    return phoneNo;
  }

  getImageBackground() {
    let url = `assets/images/privilege-card-back.png`
    return url;
  }

  itemClicked(item: any) {
    let cardFacilities = item.cardFacilities;
    let card = {
      "cardFacilities": cardFacilities,
      "cardName": item.cardName
    }
    let navigationExtras: NavigationExtras = {
      state: {
        transId: 'cardFacilities',
        workQueue: false,
        services: 1,
        code: card,
      }
    };

    this.router.navigate(['privilege-card/card-facilities'], navigationExtras);
  }

  loadCards() {
    let phone = this.getUserPhone();
    if(phone == null || phone == undefined){
      return;
    }
    let url = `CardBeneficiary/my-card/${phone}`;
    this.dataService.get(url).subscribe((response: any) => {

      this.receivedData = response;
      if (!response.success) {
        this.receivedData.data = [];
      }
      else {
        // this.loadJquery();
      }
    }, err => console.log(err));
  }

  strSanitize(str: any) {
    //return this.sanitize.sanitize(SecurityContext.STYLE, str);
  }


  makeProperCase(txt) {
    return txt.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
  };
  ngOnChanges(changes: SimpleChanges) {

    if (changes['receivedData']) { }
  }

  ngOnInit() {
    (function ($) {
      let baseColor = JSON.parse(localStorage.getItem('themeInfo'))?.base;
      $(document).ready(function () {
        $('.gift-list-slick').not('.slick-initialized').slick({
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 3,
          centerMode: true,
          //variableWidth: true,
          centerPadding: "0px",
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                variableWidth: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                variableWidth: true
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ],
          prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-right"></i></button>'
        });

        if (baseColor != undefined) {
          baseColor += ' !important'
        }
      });

    })(jQuery);
  }

  // loadJquery() {
  //   let obj = this;
  //   (function ($) {
  //     let baseColor = JSON.parse(localStorage.getItem('themeInfo'))?.base;

  //     $(document).ready(function () {
  //       $('.card-list').click(function () {
  //         var id = Number($(this).attr("id"));
  //         obj.itemClicked(obj.receivedData.data[id]);
  //       });
  //       $('.card-list').not('.slick-initialized').slick({
  //         dots: true,
  //         infinite: true,
  //         speed: 300,
  //         slidesToShow: 3,
  //         centerMode: true,
  //         centerPadding: "0px",
  //         responsive: [
  //           {
  //             breakpoint: 992,
  //             settings: {
  //               slidesToShow: 3,
  //               slidesToScroll: 2,
  //               variableWidth: true
  //             }
  //           },
  //           {
  //             breakpoint: 768,
  //             settings: {
  //               slidesToShow: 2,
  //               slidesToScroll: 2,
  //               variableWidth: true
  //             }
  //           },
  //           {
  //             breakpoint: 640,
  //             settings: {
  //               slidesToShow: 1,
  //               slidesToScroll: 1
  //             }
  //           }
  //         ],
  //         prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-left"></i></button>',
  //         nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-right"></i></button>'
  //       });

  //       if (baseColor != undefined) {
  //         baseColor += ' !important'
  //       }
  //     });



  //   })(jQuery);
  // }

  viewAllGifts() {
    this.router.navigate(['/privilege-card/mycards']);
  }

  setDesign() {
    this.viewAllBtnColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
  }
}
