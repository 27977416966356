import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '@app/components/base/base.component';

@Component({
  selector: 'appc-gift-sending-progress',
  templateUrl: './gift-sending-progress.component.html',
  styleUrls: ['./gift-sending-progress.component.scss']
})
export class GiftSendingProgressComponent extends BaseComponent {

  @Input() step: number = 0;

  //for design
  activeColor:string;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  setDesign() {
    this.activeColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base) + "; border-color: " + this.getCssWithImportant(this.themeInfo?.base);
  }

}
