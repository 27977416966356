import { ThrowStmt } from '@angular/compiler';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { Route, Router } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { AppFormComponent, IFieldConfig, DataService, FormsService, FieldTypes } from '@app/shared';
import { MerchantOutletResponse } from '@app/shared/models/view-models/merchant-outlets-response';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { copyFileSync } from 'fs';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

declare var jQuery: any;

@Component({
  selector: 'appc-merchants-grid',
  templateUrl: './merchants-grid.component.html',
  styleUrls: ['./merchants-grid.component.scss'],
  providers: [NgbCarouselConfig]
})
export class MerchantsGridComponent extends BaseComponent implements OnInit {

  @ViewChild(AppFormComponent, { static: true }) form: AppFormComponent;
  @ViewChildren("brandNav") brandNavigation: QueryList<ElementRef>;
  @ViewChild('ngslickModal') slickModal: SlickCarouselComponent;
  @ViewChild('categoryModal') categoryModal: SlickCarouselComponent;
  @ViewChild('viewoutlet') outletButton: ElementRef;

  @Output() selectedMerchantData = new EventEmitter<any>();
  @Input() selectedCategoryId: any;
  @Input() showSelection: boolean = false;
  config: IFieldConfig[];

  apiBaseUri: any;
  merchants: any[] = [];
  filteredMerchants: any[] = [];
  categories: any[] = [];
  sortedCategories: any[] = [];
  selectedCategoryIndex: number = -1;
  searchText: string = "";
  showMerchant: boolean = true;
  isMobile: boolean;
  isSlickConfigured: boolean = false;
  selectedMerchantIndex: number = -1;
  slideConfig = { "slidesToShow": 2, "slidesToScroll": 2, "nextArrow": "", "prevArrow": "" };
  slideConfigForCategory = { "slidesToShow": 4, "slidesToScroll": 4, "nextArrow": "", "prevArrow": "" };
  previousCategory: any;
  merchantShowed: boolean[] = [];
  trendingMerchant: any[] = [];
  finalList: any[] = [];
  initialMerchantList: any[] = [];
  isSearching = false;
  selectedMerchantOutlets: MerchantOutletResponse[] = [];
  selectedCategory: number = -1;
  color: string;
  backgroundColor: string;

  constructor(private dataService: DataService,
    private formsService: FormsService, private router: Router) {
    super();
    this.apiBaseUri = environment.apiUrl;
    this.getScreenSize();
  }

  ngAfterViewInit() {
    this.brandNavigation.changes.subscribe(() => {
      configureSlick();
    });
    resetDesign();
  }

  ngOnInit() {
    this.getMerchants();
    this.getMerchantCategory();

    this.config = [
      {
        name: 'searchText',
        label: '',
        type: FieldTypes.Textbox
      }];
  }
  setDesign() {
    this.backgroundColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
    this.color = 'color:' + this.getCssWithImportant(this.themeInfo?.base);
  }


  prevClicked() {
    this.slickModal.slickPrev();
  }

  nextClicked() {
    this.slickModal.slickNext();
  }

  prevTopClicked() {
    this.categoryModal.slickPrev();
  }

  nextTopClicked() {
    this.categoryModal.slickNext();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {

    if (window.innerWidth > 442) {
      (function ($) {
        if ($(window).width() > 992 && $('#brandNav') !== null) {
          $('#brandNav.slick-initialized').slick('unslick');
        }
      })(jQuery);
      this.isMobile = false;

    }
    else {
      this.isMobile = true;
    }
  }

  async delay(ms: number, action: any) {
    await new Promise(resolve => setTimeout(() => {
      this.previousCategory = action;
      this.showMerchant = true;
    }, ms)).then(() => console.log("fired"));
  }

  checkAndSet(index, item) {
    this.filteredMerchants[index].isLoaded = true;
    return true;
  }
  filterMerchant(category: any, index: number) {
    // this.resetMerchantShowed();
    this.showMerchant = false;

    this.selectedCategory = category;

    if (category != undefined && category != null && category === this.previousCategory) {
      return;
    }

    this.selectedCategoryIndex = index;
    if (this.selectedCategoryIndex > 1) {
      this.filteredMerchants.forEach(element => {
        if (element.companyCategoryId == category && (this.searchText == "" || element.name.toLowerCase().includes(this.searchText.toLowerCase()))) {
          element.showMerchant = true;
          // console.log("not trending merchant : " + element.name)
        }
        else {
          element.showMerchant = false;
        }
      });
    }
    else if (this.selectedCategoryIndex == 0) {
      this.filteredMerchants.forEach(element => {
        if (this.searchText == "" || element.name.toLowerCase().includes(this.searchText.toLowerCase()))
          element.showMerchant = true;
        else element.showMerchant = false;
      });
    }
    else {
      this.filteredMerchants.forEach(element => {
        if (element.isTrending && (this.searchText == "" || element.name.toLowerCase().includes(this.searchText.toLowerCase()))) {
          element.showMerchant = true;
        }
        else {
          element.showMerchant = false;
        }
      });
    }

    this.previousCategory = category;
    this.showMerchant = true;
  }

  searchMerchant = (searchText: any) => {
    this.searchText = searchText.target.value;
    if (this.searchText == "")
      this.filteredMerchants.forEach(p => {
        if (this.selectedCategory < 0 || (this.selectedCategory == 0 && p.isTrending))
          p.showMerchant = true
        else {
          p.showMerchant = p.companyCategoryId == this.selectedCategory;
        }
      });
    else {
      this.filteredMerchants.forEach(p => {
        if (p.name.toLowerCase().includes(this.searchText.toLowerCase()) && (this.selectedCategory < 0 || (p.companyCategoryId == 0 && p.isTrending) || p.companyCategoryId == this.selectedCategory))
          p.showMerchant = true
        else p.showMerchant = false;
      });
    }
  }

  selectedMerchant($event: any) {
    this.filteredMerchants = this.merchants.filter(c => c.name.toLowerCase() === $event.toLowerCase());
  }

  onSelectMerchant(item: any, index: any) {
    this.selectedMerchantOutlets = [];
    this.selectedMerchantIndex = index;
    if (this.selectedMerchantIndex == -1) {
      this.selectedMerchantData.next(null);
    }
    else {
      if (!this.router.url.includes('selectmerchant') && this.router.url.includes('merchant'))
        this.getSelectedMerchantOutlet(this.filteredMerchants[index].id);

      this.selectedMerchantData.next(this.filteredMerchants[index]);
    }
  }

  getSelectedMerchantOutlet(merchantId) {
    let url = `Outlets/company/${merchantId}`;
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.selectedMerchantOutlets = data;
      }
      clickOutlet();
    });
  }


  getMerchants() {
    let merchantFilterType = 0;
    let uri = `company/merchant/${merchantFilterType}`;

    this.dataService.get(uri).subscribe((data: any) => {
      let filteredData = data.map(p => {
        return {
          id: p.id,
          companyCategoryId: p.companyCategoryId,
          name: p.name,
          companyLogoUrl: p.companyLogoUrl,
          companySettings: p.companySettings,
          deliveryMethod: p.deliveryMethod,
          isTrending: p.isTrending,
          orderNo: p.orderNo,
          outletCount: p.outletCount,
          shortName: p.shortName,
          showMerchant: true,
          hasDiscount: p.hasDiscount,
          isLoaded: false
        }
      });

      this.merchants = [...filteredData];
      this.filteredMerchants = [...filteredData];
      this.finalList = [...filteredData];
      if (this.selectedCategoryId > 0) {
        this.merchants.forEach((item, index) => {
          let newElement = item;
          if (newElement.companyCategoryId == this.selectedCategoryId)
            newElement.showMerchant = true;
          else newElement.showMerchant = false;
          this.filteredMerchants[index] = newElement;
        }
        );
      }
      setTimeout(() => { });
    });
  }

  getMerchantCategory() {
    let uri = `companytypes/merchant`;
    this.dataService.get(uri).subscribe((data: any) => {
      this.categories = data;

      if (this.categories !== undefined) {
        this.categories.push({ id: -1, 'name': 'All' })
        this.categories.push({ id: 0, 'name': 'Trending' })
      };
      this.sortedCategories = this.categories.sort((n1, n2) => n1.id - n2.id);
      if (this.selectedCategoryId > 0) {
        this.selectedCategoryIndex = this.sortedCategories.findIndex(item => item.id == this.selectedCategoryId);
        //this.sortedCategories = this.sortedCategories.filter(item => item.id == this.selectedCategoryId)
      }
    });
  }

  addSlide() { }

  removeSlide() { }

  slickInit(e) {
  }

  breakpoint(e) {
  }

  afterChange(e) {
  }

  beforeChange(e) {
  }
}

function resetDesign() {
  let themeInfo = JSON.parse(localStorage.getItem("themeInfo")).base;
  (function ($) {
    $(document).ready(function () {
      $(".brand-nav-links.active").css("color", themeInfo);
    })
  })(jQuery)
}

function resetSlick() {
  (function ($) {
    $('#brandNav.slick-initialized').slick('unslick');

    $('#brandNav').not('.slick-initialized').slick({
      dots: false,
      infinite: false,
      speed: 300,
      centerMode: false,
      //variableWidth: true,
      centerPadding: "0",
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToScroll: 2,
            variableWidth: true
          }
        }
      ],
      prevArrow: '<button type="button" class="slick-prev square-shape"><i class="fas fa-arrow-left"></i></button>',
      nextArrow: '<button type="button" class="slick-next square-shape"><i class="fas fa-arrow-right"></i></button>'
    });

  })(jQuery);


}

function clickOutlet() {
  (function ($) {
    $(document).ready(function () { $("#view-outlet").click(); })

  })(jQuery);
}


function configureSlick() {
  (function ($) {
    $(document).ready(function () {
      if ($(window).width() > 992 && $('#brandNav') !== null) {
        $('#brandNav.slick-initialized').slick('unslick');
      }
      else if ($('#brandNav') !== null) {
        if ($('#brandNav') !== null) {
          $('#brandNav').not('.slick-initialized').slick({
            dots: false,
            infinite: false,
            speed: 300,
            centerMode: false,
            //variableWidth: true,
            centerPadding: "0",
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToScroll: 2,
                  variableWidth: true
                }
              }
            ],
            prevArrow: '<button type="button" class="slick-prev square-shape"><i class="fas fa-arrow-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next square-shape"><i class="fas fa-arrow-right"></i></button>'
          });

        }
      }
    });
  })(jQuery);
}

