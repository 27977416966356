import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'appc-gift-item',
  templateUrl: './gift-item.component.html',
  styleUrls: ['./gift-item.component.scss']
})
export class GiftItemComponent implements OnInit {

  @Input() giftItem: any;

  item: any;
  apiBaseUri: any;

  constructor() {
    this.apiBaseUri = environment.apiUrl;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['giftItem']) {
      this.item = this.giftItem;
    }
  }
}
