import { AuthorizeService } from './../../shared/services/authorize.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '@app/shared';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'appc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent {

  dashboardData: any;
  userName: any;
  sendBtnColor:string;
  @ViewChild("content") content: ElementRef;
  constructor(private dataService: DataService,private authorizeService:AuthorizeService,
    private router: Router, private modalService: NgbModal) {
    super();
    this.checkAuthentication();
  }

  checkAuthentication(){
    if(!this.authorizeService.isLoggedIn){
      this.router.navigate(['/authentication/login']);
    }
  }

  setDesign() {
    this.sendBtnColor = 'background-color:' + this.getCssWithImportant(this.themeInfo?.base);
  }

  ngOnInit() {

    this.getDashboardData();
    this.userName = localStorage.getItem("name");
  }

  open(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {},
			(reason) => {},
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  onSendGift() {
    this.open(this.content);
    // this.router.navigate(['/send-gift']);
  }

  getDashboardData() {
    let token = localStorage.getItem('token');
    let consumerId = localStorage.getItem("consumerId");
    if (token == null || consumerId == null) {
      this.router.navigate(['authentication/login']);
    } else {
      this.dataService.get(`dashboard/consumer-app-dashboard/consumer/${consumerId}`)
        .subscribe((response: any) => {
          this.dashboardData = response.data;
        });
    }
  }
}
