import { Component, OnInit } from '@angular/core';
import { DataService } from '@app/shared';
import { environment } from 'environments/environment';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'appc-gift-sents',
  templateUrl: './gift-sents.component.html',
  styleUrls: ['./gift-sents.component.scss']
})
export class GiftSentsComponent extends BaseComponent {

  giftsData: any[];
  apiBaseUri: any;
  availed: any[];
  expired: any[];
  sentCount: string;
  availedCount: string;
  expiredCount: string;

  //for design
  navTitleColor:string;

  constructor(private dataService: DataService) {
    super();
    this.apiBaseUri = environment.apiUrl;
  }

  ngOnInit() {
    this.getGiftsData();
    this.getGiftsAvailedData();
    this.getGiftsExpiredData();
  }

  setDesign() {
    this.navTitleColor = 'color:' + this.getCssWithImportant(this.themeInfo ?.base);
  }

  getGiftsData() {
    let uri = `consumer-offer/0/${localStorage.getItem("consumerId")}`
    this.dataService.get(uri)
      .subscribe((response: any) => {
        this.giftsData = response;
     
        if(this.giftsData != undefined && this.giftsData != null && this.giftsData.length > 0){
          this.sentCount = (this.giftsData.length > 9 ? '9' : this.giftsData.length) + ((this.giftsData.length > 9 )? '+':'');
        }
        else {this.sentCount = '0';}
      });
  }

  getGiftsAvailedData() {
    let uri = `consumer-offer/2/${localStorage.getItem("consumerId")}`
    this.dataService.get(uri)
      .subscribe((response: any) => {
        this.availed = response;
        if(this.availed != undefined && this.availed != null && this.availed.length > 0){
          this.availedCount = (this.availed.length > 9 ? '9' : this.availed.length) + ((this.availed.length > 9 )? '+':'');
        }
        else {this.availedCount = '0';}
      });
  }

  getGiftsExpiredData() {
    let uri = `consumer-offer/3/${localStorage.getItem("consumerId")}`
    this.dataService.get(uri)
      .subscribe((response: any) => {
        this.expired = response;
        if(this.expired != undefined && this.expired != null && this.expired.length > 0){
          this.expiredCount = (this.expired.length > 9 ? '9' : this.expired.length) + ((this.expired.length > 9 )? '+':'');
        }
        else {this.expiredCount = '0';}
      });
  }

}
