import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { FieldTypes, IFieldConfig, VerificationRequired } from '@app/models';
import { AppFormComponent, DataService } from '@app/shared';
import { AlertifyService } from '@app/shared/services/alertify.service';

@Component({
  selector: 'appc-offer-card-details',
  templateUrl: './offer-card-details.component.html',
  styleUrls: ['./offer-card-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OfferCardDetailsComponent extends BaseComponent implements OnInit {

  // send offer code properties
  @ViewChild(AppFormComponent, { static: true }) form: AppFormComponent;
  config: IFieldConfig[];

  providerId: any;
  offerCard: any;
  offerTemplateId: any = null;
  offerId: any = null;
  offerCode: any;
  loggedInUserPhoneNumber: any = null;

  instructionCollapse: boolean = false;
  instructionExpand: boolean = true;

  providerSettings: any;
  navTitleColor:any;
  continueBtnColor:any;

  isVerificationRequired: string = VerificationRequired[VerificationRequired.Yes];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertifyService,
    private dataService: DataService) {

    super();
    this.loggedInUserPhoneNumber = localStorage.getItem("phoneNumber");
    this.offerId = localStorage.getItem('offerId');

    this.providerId = this.route.snapshot.params.providerId;
    this.offerTemplateId = this.route.snapshot.params.offerTemplateId;
    let vr: string = this.route.snapshot.queryParams['vr'] || VerificationRequired.Yes;
    this.isVerificationRequired = VerificationRequired[vr] || this.isVerificationRequired;

    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state != undefined && state.code != undefined && state.code != null) {
      this.offerId = state.code?.id;
    }
  }

  ngOnInit() {
    if (this.isVerificationRequired === VerificationRequired[VerificationRequired.Yes]) {
      this.verifyCustomer();
    }
    this.buildForm();
    this.loadOfferCard(this.loggedInUserPhoneNumber);
  }

  verifyCustomer() {
    let token = localStorage.getItem('token');
    let url = `OfferProvider/provider-offer-config/${this.providerId}/2`;

    this.dataService.get(url).subscribe((response: any) => {
      if (response.success) {
        this.providerSettings = response.data;
        if ((token === null || token === undefined) && this.providerSettings?.verificationRequired) {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              providerId: this.providerId,
              offerTemplateId: this.offerTemplateId
            }
          };
          this.router.navigate([`offer-card/verify-customer`], navigationExtras);
        }
      }
    }, err => console.log(err));
  }

  loadOfferCard(phoneNumber: any = null) {

    let url = `CorporateOffers/offer-card-details?offerTemplateId=${this.offerTemplateId}`;
    url = (this.offerId === null || this.offerId === 'null' || this.offerId === undefined) ? url : `${url}&offerId=${this.offerId}`;
    url = (phoneNumber === null || phoneNumber === 'null' || phoneNumber === undefined)
      ? url : `${url}&receiverPhone=${phoneNumber}`;

    this.dataService.get(url).subscribe((response: any) => {
      if (response.success) {
        this.offerCard = response.data;
        localStorage.setItem('offerId', this.offerCard?.offerId);
        this.offerId = this.offerCard?.offerId;
      }
      else {
        this.alertService.error(response.data);
      }

      console.log(this.offerCard);
    }, err => console.log(err));
  }

  expandCollapse() {
    this.instructionCollapse = this.instructionCollapse ? false : true;
    this.instructionExpand = this.instructionExpand ? false : true;
  }

  buildForm() {
    this.config = [
      {
        name: 'offerCodeReceiverPhone',
        label: "Enter Recipient's Mobile No.",
        type: FieldTypes.Textbox,
        validation: [Validators.required],
        placeholder: '01xxxxxxxxx',
        showIcon: false,
        value: ''
      },
      {
        name: 'offerCodeReceiverEmail',
        label: "Enter Recipient's Email (optional)",
        type: FieldTypes.Email,
        placeholder: 'adam@gmail.com',
        showIcon: false,
        value: ''
      },
    ];
  }

  onSubmit() {
    let isDirty = false;

    if (this.form.form.status === "INVALID") {
      isDirty = true;
      this.form.form.markAllAsTouched();
    }
    if (isDirty) return;

    let data = this.form.value;

    let codeReceiverPayload = {
      'offerTemplateId': this.offerCard.offerTemplateId,
      'offerId': this.offerId,
      'codeReceiverPhoneNumber': data.offerCodeReceiverPhone,
      'codeReceiverEmail': data.offerCodeReceiverEmail
    };

    console.log(codeReceiverPayload);

    let url = `CorporateOffers/send-offer-code`;
    this.dataService.post(url, codeReceiverPayload).subscribe((response: any) => {
      if (response.success) {
        this.form.form.reset();
        this.alertService.success(response.data);
        this.loadOfferCard(codeReceiverPayload.codeReceiverPhoneNumber);
      }
      else {
        this.alertService.error(response.data);
      }
    }, err => console.log(err));
  }
}
