import { MyPrivilegeCardFacilitiesComponent } from './components/my-card/my-privilege-card-facilities/my-privilege-card-facilities.component';
import { MyPrivilegeCardComponent } from './components/my-card/my-privilege-card/my-privilege-card.component';
import { OutletsComponent } from './components/outlets/outlets.component';
import { Routes } from '@angular/router';
import {
  PrivacyComponent, TokenLoginComponent, DashboardComponent, GiftsComponent, MerchantListComponent,
  GiftDetailsComponent, SendGiftsComponent, GiftInformationComponent,
  GiftConfirmationComponent, GiftDeliveredComponent, GiftPaymentComponent, PopularGiftListComponent, GiftSentsComponent, SentGiftDetailsComponent
} from '@app/components';
import { GiftMerchantSelectionComponent } from './components/gifts/gift-merchant-selection/gift-merchant-selection.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OfferListComponent } from './components/offer-list/offer-list.component';
import { ReceivedGiftsComponent } from './components/dashboard/received-gifts/received-gifts.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { QrScannerComponent } from './components/shared/qr-scanner/qr-scanner.component';
import { OfferCardDetailsComponent, VerifyCustomerComponent } from './components/offer-cards';

export const routes: Routes = [
  // { path: '', component: HomeComponent, pathMatch: 'full', data: { state: 'home' } },
  { path: '', component: DashboardComponent, pathMatch: 'full' },
  // { path: 'examples', loadChildren: () => import('./+examples/examples.module').then(m => m.ExamplesModule) },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'token-verification/:token', component: TokenLoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'my-gifts', component: GiftsComponent },
  { path: 'received-gifts', component: ReceivedGiftsComponent },
  { path: 'gifts/:giftType/:giftId', component: GiftDetailsComponent },
  { path: 'merchants', component: MerchantListComponent },
  { path: 'gifts/:giftType/:giftId/selectmerchant', component: GiftMerchantSelectionComponent },
  { path: 'send-gift', component: SendGiftsComponent },
  { path: 'send-gift-information', component: GiftInformationComponent },
  { path: 'send-gift-confirmation', component: GiftConfirmationComponent },
  { path: 'send-gift-payment', component: GiftPaymentComponent },
  { path: 'send-gift-delivered/:deliveryStatus/:orderId', component: GiftDeliveredComponent },
  { path: 'popular-gifts', component: PopularGiftListComponent },
  { path: 'my-offers', component: OfferListComponent },
  { path: 'sent-gifts', component: GiftSentsComponent },
  { path: 'sent-gifts/:deliveryStatus/:giftId', component: SentGiftDetailsComponent },
  { path: 'edit-profile', component: ProfileComponent },
  { path: 'about-golp', component: AboutUsComponent },
  { path: 'scan-qrcode', component: QrScannerComponent },
  { path: "ol/:merchantId", component: OutletsComponent },
  { path: "privilege-card/mycards", component: MyPrivilegeCardComponent },
  { path: "privilege-card/card-facilities", component: MyPrivilegeCardFacilitiesComponent },
  { path: "offer-card/card-details/:providerId/:offerTemplateId", component: OfferCardDetailsComponent },
  { path: "offer-card/verify-customer", component: VerifyCustomerComponent },
];
